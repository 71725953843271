import React from 'react';
import { SplitButton } from 'primereact/splitbutton';
import {
    handleBillSameTeleplanPatient,
    handleBillSameTeleplanPatientPOS,
    handleBillSameTeleplanPatientGroup,
    handleBillSamePrivatePatient,
    handleBillSameEClaim,
    handleWSBCReportWithSamePatient,
} from './billSamePatientHelpers';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';

const BillSamePatientSplitButton = ({ history, patientsList, user, showEClaimButton }) => {

    const isMobileOnly = useSelector((state) => state.core.window.isMobileOnly);

    const menuItems = [
        !isMobileOnly && {
            label: 'Picker',
            command: handleBillSameTeleplanPatientPOS(history, patientsList),
            template: (item) => (
                <Button
                    className="p-button-text w-full"
                    style={{ textAlign: 'left' }}
                    label={item.label}
                    onClick={handleBillSameTeleplanPatientPOS(history, patientsList)}
                />
            ),
        },
        {
            label: 'Teleplan',
            command: handleBillSameTeleplanPatient(history, patientsList),
            template: (item) => (
                <Button
                    className="p-button-text w-full"
                    style={{ textAlign: 'left' }}
                    label={item.label}
                    onClick={handleBillSameTeleplanPatient(history, patientsList)}
                />
            ),
        },
        !isMobileOnly && { // Exclude "Batch" on mobile devices
            label: 'Batch',
            command: handleBillSameTeleplanPatientGroup(history, patientsList),
            template: (item) => (
                <Button
                    className="p-button-text w-full"
                    style={{ textAlign: 'left' }}
                    label={item.label}
                    onClick={handleBillSameTeleplanPatientGroup(history, patientsList)}
                />
            ),
        },
        {
            label: 'Private Invoice',
            command: handleBillSamePrivatePatient(history, patientsList),
            template: (item) => (
                <Button
                    className="p-button-text w-full"
                    style={{ textAlign: 'left' }}
                    label={item.label}
                    onClick={handleBillSamePrivatePatient(history, patientsList)}
                />
            ),
        },
        user?.canBillWCB && {
            label: 'WSBC Report',
            command: handleWSBCReportWithSamePatient(history, patientsList),
            template: (item) => (
                <Button
                    className="p-button-text w-full"
                    style={{ textAlign: 'left' }}
                    label={item.label}
                    onClick={handleWSBCReportWithSamePatient(history, patientsList)}
                />
            ),
        },
        showEClaimButton && {
            label: 'eClaims',
            command: handleBillSameEClaim(history, patientsList),
            template: (item) => (
                <Button
                    className="p-button-text w-full"
                    style={{ textAlign: 'left' }}
                    label={item.label}
                    onClick={handleBillSameEClaim(history, patientsList)}
                />
            ),
        },
    ].filter(Boolean);

    return (
        <SplitButton
            label="New Claim"
            // className='custom-outlined-button'
            // outlined
            icon='pi pi-plus'
            model={menuItems}
            onClick={!isMobileOnly ? handleBillSameTeleplanPatientPOS(history, patientsList): handleBillSameTeleplanPatient(history, patientsList)} // Default action
        />
    );
};

export default BillSamePatientSplitButton;
