/**
 * Added by VB
 */

import coreReducer from '../core/reducers/core.reducer';
import claimsReducer from '../claims/reducers/claims.reducer';
import patientsReducer from '../patients/reducers/patients.reducer';
import authReducer from '../auth/reducers/auth.reducer';
import reportsReducer from '../reports/reducers/reports.reducer';
import clinicReducer from '../clinic/reducers/clinic.reducer';
import preferencesReducer from '../preferences/reducers/preferences.reduser';
import dashboardReducer from '../dashboard/reducers/dashboard.reducer';
import practitionersReducer from '../practitioners/reducers/practitioners.reducer';
import uploadReducer from '../mailbox/reducers/upload.reducer';
import noteReducer from '../notes/reducers/notes.reducer';
import roleReducer from '../roles/reducers/roles.reducer';
import cashFlowReducer from '../cashFlow/reducers/cashFlow.reducer';
import instructionsReducer from '../instructions/reducers/instructions.reducer';

const reducers = {
  user: authReducer,
  patients: patientsReducer,
  practitioners: practitionersReducer,
  reports: reportsReducer,
  core: coreReducer,
  dashboard: dashboardReducer,
  claims: claimsReducer,
  clinic: clinicReducer,
  preferences: preferencesReducer,
  notes: noteReducer,
  //scheduler: synkSchedulerReducer,
  upload: uploadReducer,
  roles: roleReducer,
  cashFlow: cashFlowReducer,
  instructions: instructionsReducer
};

export default reducers;
