import { combineReducers } from 'redux';
import reducers from './modules/reducers';
import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import initialReducers from './modules/reducers/initialReducers';

export const RESET_STATE = 'RESET_STATE';

export const resetState = () => ({
  type: RESET_STATE
});

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  if (action.type === RESET_STATE) {
    state = combineReducers(initialReducers);
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['patients', 'notes', 'cashFlow', 'instructions']
};

const logger = createLogger({
  collapsed: true
});

const composeEnhancers = composeWithDevTools({});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunkMiddleware, logger)));

const persistor = persistStore(store);

export { store, persistor };
