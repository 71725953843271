import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { setLoginView } from '../../../actions/auth.actions.creators';
import { shouldUsePin } from '../../../../utils/pinCode';

const LoginWithPinButton = () => {
  const dispatch = useDispatch();

  if (!shouldUsePin()) {
    return null;
  }

  return <Button label="Login with PIN-code" link onClick={() => dispatch(setLoginView('loginWithPin'))} />;
};

export default LoginWithPinButton;
