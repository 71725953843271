import React from 'react';
import { SplitButton } from 'primereact/splitbutton';
import { Button } from 'primereact/button';

const LabelSplitButton = ({ 
    loading, 
    onAutofillClick, 
    handleOpenCropper, 
    isMobileOnly, 
    autofillButtonRef 
}) => {
    const items = [
        {
            label: 'Cropper',
            icon: 'pi pi-arrows-alt',
            command: () => handleOpenCropper(),
            template: (item) => (
                <Button
                    className="p-button-text w-full"
                    style={{ textAlign: 'left' }}
                    label={item.label}
                    icon={item.icon}
                    onClick={handleOpenCropper}
                />
            ),
        },
    ];

    return (
        <div style={{ display: 'inline-block' }}>
            <SplitButton
                label={loading ? 'Loading' : (isMobileOnly ? 'Label' : 'Upload Label')}
                icon={loading ? 'pi pi-spin pi-spinner' : (isMobileOnly ? 'pi pi-camera' : 'pi pi-upload')}
                onClick={onAutofillClick}
                model={items}
                ref={autofillButtonRef}
                // className="autofill-split-button"
            />
        </div>
    );
};

export default LabelSplitButton;
