import React from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { Button } from 'primereact/button';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { t } from '../../../../../../service/localization/i18n';

const AddPractitionerButton = ({ mini = false, className, onAdd, style }) => {
  const { setLocalState } = useFormContext();
  const { isMobile } = useSelector((state) => state.core.window);

  const handleAdd = () => {
    onAdd ? onAdd() : setLocalState((prevState) => ({ ...prevState, addNewPractitionerDialog: true }));
  };

  return (
    <>
      {mini ? (
        <Button
          id={elementIDs.addNewPractitioner}
          className={className || 'p-button-text'}
          style={style || {}}
          type="button"
          tooltip={!isMobile && t('Add_new_practitioner')}
          tooltipOptions={{ position: 'top' }}
          icon="pi pi-plus"
          onClick={handleAdd}
        />
      ) : (
        <Button
          id={elementIDs.addNewPractitioner}
          style={style || { maxHeight: '32px', minWidth: '172px' }}
          className="p-button-outlined"
          label={t('Add_new_practitioner')}
          type="button"
          icon="pi pi-plus"
          onClick={handleAdd}
        />
      )}
    </>
  );
};

export default AddPractitionerButton;
