import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { rowActionsMenuItems } from './ClaimNotesTable/helpers/rowActionsMenuItems'
import MesTable from './MessagesIndex';
import { getMessages } from '../../../service/ClinicService';

const MessagesTable = () => {
    ;
    const { DCNGuid } = useSelector((state) => state.user.details);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [messagesesData, setRemittancesData] = useState({ records: [], totalRecords: 0 });
    const [loading, setLoading] = useState(false);

    const fetchData = async (pageNum, pageSizeNum) => {
        setLoading(true);
        try {
            const data = await getMessages(DCNGuid, pageNum, pageSizeNum);

            setRemittancesData({
                records: data.records,
                totalRecords: data.totalRecords,
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(async () => {
        fetchData(page, pageSize);
    }, []);


    const onPage = (newPage, newPageSize) => {
        setPage(newPage);
        setPageSize(newPageSize);
        fetchData(newPage, newPageSize)
    };

    return (
        <>
            <MesTable
                records={messagesesData.records}
                page={page}
                pageSize={pageSize}
                totalRecords={messagesesData.totalRecords}
                onPageChange={(e) => onPage(e.page + 1, e.rows)}
                loading={loading}
                rowActionsMenuItems={rowActionsMenuItems}
                inboxType="claim"
                fetchData={fetchData} 
            />
        </>
    );
};

export default MessagesTable;
