import React from 'react';
import { useFormContext } from 'react-hook-form';
import DatePicker from './Common/DatePicker';
import { inputs } from '../../../helpers/inputs';
import { useServiceDate } from '../../../hooks/useServiceDate';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import SwitchMultipleDates from './SwitchMultipleDates';
import { InputLabel } from '../../../../../../../components/Inputs/InputWrapper/LabelLayout';
import ToggleCatalogsButton from '../../ActionButtons/ToggleCatalogsButton';

const ServiceDate = () => {
  const { onChangeServiceDate } = useServiceDate();
  const { watch } = useFormContext();
  const status = watch(inputs.status.name);

  const lableContent = (
    <div className="flex justify-content-between align-items-center">
      <div className="flex">
        <InputLabel name={inputs.serviceDate.name} label={inputs.serviceDate.label} required />
        <div className="ml-3">
          <SwitchMultipleDates />
        </div>
      </div>
      <ToggleCatalogsButton />
    </div>
  );

  return (
    <DatePicker
      id={elementIDs.serviceDate}
      required
      status={status}
      input={inputs.serviceDate}
      onChange={onChangeServiceDate}
      lableContent={lableContent}
    />
  );
};

export default ServiceDate;
