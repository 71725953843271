import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import PageWrap from '../../../../components/Wraps/PageWrap/PageWrap';
import PinCode from '../../../../components/Widgets/PinCode';
import MobileFeatureAlert from './MobileFeatureAlert';

import { pinNotMatchToast } from './pinNotMatchToast';
import { createPinCode } from '../../actions/user.action.creators';
import { localStorageKeys } from '../../../config/localStorageKeysConfig';
import { setToastMessage } from '../../../core/actions/core.action.creators';
import { hashPinCode, showPin } from '../../../utils/pinCode';
import { v4 as uuidv4 } from 'uuid';

const CreatePinCode = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.details);
  const subtitle = 'Use PIN to log in on this device';

  const [state, setState] = useState({
    pin: '',
    step: 1
  });

  const onNext = (data) => {
    setState((prevState) => ({ ...prevState, pin: data.pin, step: 2 }));
  };

  const onSave = async (data) => {
    // Verify if the entered pin code matches the new pin
    if (state.pin !== data.pin) {
      return pinNotMatchToast();
    }

    const hashedPin = await hashPinCode(data.pin);
    const deviceGuid = uuidv4();

    // Attempt to create the pin code by making an API call
    const response = await createPinCode({
      DoctorGuid: user.UserGuid,
      DCNGuid: user.DCNGuid,
      DeviceGuid: deviceGuid,
      DeviceName: '',
      Fingerprint: hashedPin
    });

    if (response) {
      // If API call is successful, store "usePin" as `true` in local storage
      localStorage.setItem(
        localStorageKeys.pinCode,
        JSON.stringify({ usePin: true, DeviceGuid: deviceGuid, DCNGuid: user.DCNGuid, Signature: response.Signature })
      );
      dispatch(setToastMessage({ message: 'Your PIN is set! Use it to log in securely.', type: 'success', lifeTime: 7000 }));
      history.push('/');
    } else {
      dispatch(setToastMessage({ message: 'An error occurred while setting your PIN. Please try again.', type: 'error', lifeTime: 10000 }));
    }
  };

  const onBack = () => {
    setState((prevState) => ({ ...prevState, step: 1 }));
  };

  const onCancel = () => {
    history.push('/');
  };

  if (!showPin()) {
    return <MobileFeatureAlert />;
  }

  return (
    <PageWrap>
      <div className="flex gap-3">
        {state.step === 1 && <PinCode title="Create PIN code" subtitle={subtitle} onCancel={onCancel} submitButtonLabel="Next" onSubmit={onNext} />}
        {state.step === 2 && <PinCode title="Confirm PIN code" subtitle={subtitle} onBack={onBack} onCancel={onCancel} onSubmit={onSave} />}
      </div>
    </PageWrap>
  );
};

export default CreatePinCode;
