import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import TablePaginator from '../../../components/Paginators/TablePaginator';
import CircularProgress from '../../../components/Misc/Loader/CircularProgress/CircularProgress';
import { formatDatewithMinutes } from '../../utils/formatDate';
import MessagesDialog from './MessagesDialog';
import '../../mails/components/NoteTable/NoteTable.scss';
import { updateNoteStatus } from '../../claims/actions/claims.action.creators';



const MesTable = ({ records, page, pageSize, totalRecords, onPageChange, loading, fetchData }) => {


    const [isLoading, setIsLoading] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [dialogVisible, setDialogVisible] = useState(false);

    const [selectedRow, setSelectedRow] = useState(null); // Track the selected row

    const first = (page - 1) * pageSize;

    const onRowSelect = async (e) => {
      // const record={...e.value, NoteGuid: e.value.RowGuid};
      // updateNoteStatus(record, 1);
      setSelectedRecord(e.value); // Set the selected record
      setDialogVisible(true); // Open the dialog
      await fetchData(page, pageSize);
    };

    const onRecordView = (rowData) => {
      const record={...rowData, NoteGuid: rowData.RowGuid};
      updateNoteStatus(record, 1);
    }

    const rowClassName = (rowData) => {
      console.log(rowData);
        return {
            'font-bold': rowData.Status === "0",
            'cursor-pointer': true,
            'row-selected': rowData === selectedRow, // Apply the selected class if this row is the selected one
            backgroundColor: rowData === selectedRow ? '#e0e0e0' : 'transparent', // Inline style for selected row
        };
    };

    const dateBodyTemplate = (record) => {
        return (
            <div className="w-full p-0 flex align-items-center px-0 py-3" style={{ height: '55px' }}>
                {formatDatewithMinutes(record?.MessageDate)}
            </div>
        );
    };
    const messageBodyTemplate = (record) => {
        // Determine the end position for the substring
        const endPos = record?.MessageLine?.indexOf('\r') !== -1 
          ? record.MessageLine.indexOf('\r') 
          : 80;
      
        // Get the message substring
        const message = record?.MessageLine?.substring(0, endPos);
      
        // Append ellipsis if the message length exceeds 65 characters
        const displayMessage = message.length > 65 
          ? `${message.substring(0, 80)}...` 
          : message;
      
        return (
          <div className="w-full p-0 flex align-items-center px-0 py-3" style={{ height: '55px' }}>
            {displayMessage}
          </div>
        );
      };

    const viewBodyTemplate = (record) => {      
      return (
          <div className="w-full p-0 flex align-items-center px-0 py-3" style={{ height: '55px' }}>
              <MessagesDialog record={record} onVisible={onRecordView} />
          </div>
      );
    };
  
  return (
    <div className="memos-table-wrap">
      {(loading || isLoading) && <CircularProgress />}
      <DataTable
        lazy
        value={records}
        tableStyle={{ minWidth: '50rem' }}
        size="small"
        scrollable
        scrollHeight="flex"
        rows={pageSize}
        //header={header}
        first={first}
        filterDisplay="menu"
        selectionMode="single"
        selection={selectedRow}
        onSelectionChange={onRowSelect}
        rowClassName={rowClassName}
        emptyMessage={!records.length && loading ? 'Loading...' : 'No messages found'} 
        footer={<TablePaginator first={first || 0} rows={pageSize || 0} totalRecords={totalRecords || 0} onPageChange={onPageChange} />}
      >
        <Column
          className="memos-column-content"
          field="Date"
          header="Date"
          style={{ minWidth: '120px', maxWidth: '120px' }}
          body={(record) => dateBodyTemplate(record)}
        />
        <Column
          className="memos-column-content"
          field="Message"
          style={{ minWidth: '400px', maxWidth: '400px' }}
          body={(record) => messageBodyTemplate(record)}
          header="Message"
        />
        <Column
          className="memos-column-content"
          field="View"
          style={{ minWidth: '40px', maxWidth: '40px' }}
          body={(record) => viewBodyTemplate(record)}
          header="View"
        />
      </DataTable>

      {/* {dialogVisible && (           !!working on it
            <MessagesDialog
                visibleState={dialogVisible}
                onHide={() => setDialogVisible(false)}
                record={selectedRecord}
            />
        )} */}
      
    </div>
  );
};

export default MesTable;


