import React from 'react';
import { Badge } from 'primereact/badge';

const BetaBadge = ({position = 'absolute' ,top = '-10px', right = '-10px', style = {}  }) => {
    return (
        <Badge 
            value="Beta" 
            severity="info" 
            style={{
                position: position,
                top: top,
                right: right,
                background: 'white',
                color: '#2196F3',
                border: '1px solid #2196F3',
                ...style
            }} 
        />
    );
}

export default BetaBadge;
