export default {
  APP_MODE: 'APP_MODE',
  WINDOW_RESIZE: 'WINDOW_RESIZE',
  SET_COLORS: 'SET_COLORS',
  SET_DIRTY: 'SET_DIRTY',
  SET_CLEAN: 'SET_CLEAN',
  SET_LOADER: 'SET_LOADER',
  SET_DEEPROW: 'SET_DEEPROW',
  TOAST_MESSAGE: 'TOAST_MESSAGE',
  TOAST_MESSAGE_POSITION: 'TOAST_MESSAGE_POSITION',
  LAYOUT_MODE: 'LAYOUT_MODE',
  CLOSE_TOAST_MESSAGE: 'CLOSE_TOAST_MESSAGE',
  TOGGLE_FILTERS: 'TOGGLE_FILTERS',
  TOGGLE_SORTING: 'TOGGLE_SORTING',
  SET_PRINT_DATA: 'SET_PRINT_DATA',
  SET_LOADING: 'SET_LOADING',
  SET_ELIGIBILITY_ERROR: 'SET_ELIGIBILITY_ERROR',
  SHOW_DELETE_PIN_DIALOG: 'SHOW_DELETE_PIN_DIALOG'
};
