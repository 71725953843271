import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { formatDatewithMinutes } from '../../utils/formatDate';



const MessagesDialog = ({ record, onVisible }) => {
    const PopUpMessageRecord = (record, onVisible) => {

        const { isMobile, clientWidth } = useSelector((state) => state.core.window);
        const date = formatDatewithMinutes(record?.MessageDate);
        const [visible, setVisible] = useState(false);
        const dialogRef = useRef(null);
        const dialogHeaderRef = useRef(null);



        const handleOpenModal = () => {
            setVisible(true); 
            if (onVisible && typeof onVisible === 'function') {
                onVisible(record);
            }
        };

        const handleCloseModal = () => {
            setVisible(false);
        };

        // useEffect(() => {
        //     if (visibleState) {
        //         // Additional logic if needed when visibility is true
        //         console.log('Dialog should be visible', visibleState);
        //         setVisible(true);
        //     }
        // }, [visibleState]);


        // const messageLines = record.record.MessageLine.trim().split('\n');
        // const newMessage = messageLines.map((line, index) => {
        //     return line.trim();
        // }).join('<br />');

        function cleanColons(text) {
            // Replace colon and following whitespaces with just a colon and a single space

            return text.replace(/:\s+/g, ': ');
        }

        function addLineBreaks(lines) {
            return lines.map(line => line.trim()).join('<br />');
        }


        const messageLines = record.MessageLine.trim().split('\n');

        // Step 1: Add <br /> tags
        const withLineBreaks = addLineBreaks(messageLines);

        // Step 2: Clean up colons
        const newMessage = cleanColons(withLineBreaks);


        const handlePrint = () => {

        const printContents = dialogRef.current.innerHTML;
        const headerContents = dialogHeaderRef.current ? dialogHeaderRef.current.innerHTML : '';


        const iframe = document.createElement('iframe');
        iframe.style.position = 'absolute';
        iframe.style.width = '0px';
        iframe.style.height = '0px';
        iframe.style.border = 'none';
        document.body.appendChild(iframe);

        const doc = iframe.contentWindow.document;
        doc.open();
        doc.write(`
            <html>
                <head>
                    <title></title>
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            margin: 20px;
                            line-height: 1.7;
                        }
                        p {
                            
                            font-size: 12px !important; /* Adjust this value to change the font size */
                        }
                        h3 {
                            font-size: 16px; /* Adjust this value to change the header font size */
                        }
                    </style>
                </head>
                <body>
                    ${headerContents}
                    ${printContents}
                </body>
            </html>
        `);    doc.close();

        iframe.contentWindow.focus();
        iframe.contentWindow.print();

        document.body.removeChild(iframe); // Clean up the iframe after printing
    };
    return (
        <div className='flex justify-content-center'>
            <Button icon="pi pi-external-link"
                className="p-button-rounded"
                tooltip="View full message"
                tooltipOptions={{ position: 'top' }} onClick={handleOpenModal} />

            <Dialog
                header={
                    <div ref={dialogHeaderRef}>
                        <h3 style={{ textAlign: 'center', margin: 0 }}>{`Message from Teleplan - ${date}`}</h3>
                    </div>
                } visible={visible}
                style={{ width: isMobile ? '90vw' : '60vw', maxHeight: '90vh', overflowY: 'auto' }}
                onHide={handleCloseModal}
                dismissableMask
            >
                <div className='p-grid p-dir-col'>
                    <div ref={dialogRef} className='flex' style={{ flexDirection: isMobile ? 'column' : 'row' }}>
                        <div className='p-col px-2' style={{ margin: 'auto', padding: isMobile ? '1rem 0' : '0' }}>
                            <div className="p-field-radiobutton" style={{ marginBottom: '1rem' }}>
                                <p
                                    style={{
                                        whiteSpace: 'pre-wrap',
                                        fontFamily: 'inherit !important', // Force the use of the default font
                                        fontSize: 'inherit',    // Ensure the default font size is used
                                        lineHeight: '1.7',                 // Adjust this value to increase/decrease line spacing
                                        color: 'inherit !important',
                                        margin: '0 !important',
                                        padding: '0 !important'
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: newMessage,
                                        
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='p-col'>
                        <div className='p-col py-2 px-2 flex justify-content-center'>
                            <Button label="Print" onClick={handlePrint} className='p-mt-2 p-ml-2' />
                            <Button type = "button" label="Close" outlined onClick={handleCloseModal} className='p-mt-2' style={{ marginLeft: '10px' }} />
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
        
    };
    return PopUpMessageRecord(record, onVisible);
};

//export default PopUpMessageRecord;

export default MessagesDialog;