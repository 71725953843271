import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { WrappedDatePicker } from '../../../../../../../../components/Inputs';

// interface = {
//   input: {
//     name: string,
//     label: string
//     placeholder?: string
//   },
//  yearRange?: string,
//  selectionMode?: single | multiple,
//  required?: boolean,
//  showOnFocus?: boolean,
//  readOnlyInput?: boolean,
//  status?: number | string,
//  labelOptionalContent?: () => JSX.Element | JSX.Element
//  footerContent?: () => JSX.Element | JSX.Element
//  onChange?: (value: Date) => void,
//  handleBlur?: (value: Date) => void
// };

const DatePicker = ({ input, required = false, onChange, ...props }) => {
  const name = input.name;
  const { errors, control } = useFormContext();
  const errorMessage = errors[name]?.message;

  const handleChange = (value, field) => {
    if (onChange) return onChange(value);
    field.onChange(value);
  };

  const handleBlur = (value, field) => {
    if (props.onBlur) props.onBlur(value, field);
  };

  const datePickerInput = (field) => {
    const value = field.value;
    const selectionMode = Array.isArray(value) ? 'multiple' : 'single';

    return (
      <WrappedDatePicker
        {...props}
        input={input}
        ref={field.ref}
        value={value}
        required={required}
        status={props.status}
        showOnFocus={props.showOnFocus}
        readOnlyInput={props.readOnlyInput}
        errorMessage={errorMessage}
        selectionMode={selectionMode}
        labelOptionalContent={props.labelOptionalContent}
        footerContent={props.footerContent}
        onChange={(value) => handleChange(value, field)}
        onBlur={(value) => handleBlur(value, field)}
      />
    );
  };

  return <Controller name={name} control={control} render={({ field }) => datePickerInput(field)} />;
};

export default DatePicker;
