import actionTypes from '../actions/instructions.actions.types';

export const prefsInitialState = {
  instructions: []
};

export default function instructionsReducer(state = prefsInitialState, action) {
  const { results } = action;

  switch (action.type) {
    case actionTypes.GET_INSTRUCTIONS:
      return {
        ...state,
        instructions: results
      };

    default:
      return state;
  }
}
