import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import { routes } from '../../../routes/routes';
import { ButtonWithBadge } from '../../../components/Buttons';

function MailSidebar() {
  const history = useHistory();
  const totalUnreadNotes = useSelector((state) => state.notes.totalUnreadNotes);
  const totalUnreadClaimNotes = totalUnreadNotes?.find((i) => i.Entity === 'claim');
  const totalUnreadPatientNotes = totalUnreadNotes?.find((i) => i.Entity === 'patient');
  const totalUnreaRemittances = totalUnreadNotes?.find((i) => i.Entity === 'remittance');
  const totalUnreadMessages = totalUnreadNotes?.find((i) => i.Entity === 'message');
  
  const items = [
    {
      label:'Claim Notes',
      icon: 'pi pi-file-edit',
      toolTip: "Internal notes about claims",
      badge: totalUnreadClaimNotes?.TotalUnread, // Waitiong for BE to show unread notes (WIN-167)
      to: routes.claimNotesInbox.path,
      subItems: [
        // Add subItems here          

        {
          label: 'Starred',
          icon: 'pi pi-star',
          badge: 3
          //to: '/starred'
        },
        {
          label: 'Bookmark',
          icon: 'pi pi-bookmark',
          badge: 1
          //to: '/bookmark'
        },
      ]
    },
    {
      label: 'Patient Notes',
      icon: 'pi pi-user',
      toolTip: "Internal notes about patients",
      badge: totalUnreadPatientNotes?.TotalUnread, // Waitiong for BE to show unread notes (WIN-167)
      to: routes.patientNotesInbox.path,
      subItems: [
        // Add subItems here
        {
          label: 'Starred',
          icon: 'pi pi-star',
          badge: 3
          //to: '/starred'
        },
        {
          label: 'Bookmark',
          icon: 'pi pi-bookmark',
          badge: 1
          //to: '/bookmark'
        }
      ]
    },
    {
      label: 'Remittances',
      icon: 'pi pi-book',
      toolTip: 'Teleplan payment reports',
      badge: totalUnreaRemittances?.TotalUnread, // Waitiong for BE to show unread notes (WIN-167)
      to: routes.remittances.path,
      subItems: [
        // Add subItems here
        {
          label: 'Starred',
          icon: 'pi pi-star',
          badge: 3
          //to: '/starred'
        },
        {
          label: 'Bookmark',
          icon: 'pi pi-bookmark',
          badge: 1
          //to: '/bookmark'
        }
      ]
    },
    {
      label: 'Messages',
      icon: 'pi pi-envelope', 
      toolTip: "Teleplan Messages",
      badge: totalUnreadMessages?.TotalUnread, // Waitiong for BE to show unread notes (WIN-167)
      to: routes.messages.path,
      subItems: [
        // Add subItems here
        {
          label: 'Starred',
          icon: 'pi pi-star',
          badge: 3
          //to: '/starred'
        },
        {
          label: 'Bookmark',
          icon: 'pi pi-bookmark',
          badge: 1
          //to: '/bookmark'
        }
      ]
    }
  ];

  const navigate = (item) => {
    if (item.to) {
      history.push(item.to);
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
}, []);

  return (
    <>
      <h3 className="mb-5 hidden md:block">Memos</h3>
      <div className="flex md:flex-column gap-2">
        {items.map((i, index) => (
          <>
            {windowWidth > 768 && (

            <div key ={`memo${index}`}className="flex flex-col gap-2">
              {index === 0 && <p
                style={{
                  fontSize: '1.25rem',
                  fontWeight: 'bold',
                  color: '#333',
                  marginBottom: '0.5rem',
                  padding: '0.5rem 0',
                  //borderBottom: '2px solid #ddd',
                }}
              >Internal Memos</p>}
              {index === 2 && <p
                style={{
                  fontSize: '1.25rem',
                  fontWeight: 'bold',
                  color: '#333',
                  marginBottom: '0.5rem',
                  padding: '0.5rem 0',
                  //borderBottom: '2px solid #ddd',
                }}
              >Teleplan Memos</p>}
            </div>
            )}
            <ButtonWithBadge key={i.label} value={i.badge} severity="warning" rootClassName="w-full">
              <Button text={!history.location.pathname.includes(i.to)} tooltip={i.toolTip} tooltipOptions={{ position: 'top' }} icon={i.icon} style={{textAlign: 'left'}}className="w-full" label={windowWidth < 768 ? '' : i.label} onClick={() => navigate(i)} />
            </ButtonWithBadge>
          </>
        ))}
      </div>
    </>
  );
}

export default MailSidebar;
