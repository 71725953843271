import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from 'primereact/button';

const MobileFeatureAlert = () => {
  const history = useHistory();

  return (
    <div className="flex justify-content-start align-items-center flex-column pt-6 px-3 w-full">
      <span className="text-xl text-center">The PIN code feature is available only on mobile devices.</span>
      <Button className="mt-4" label="Return to Home" onClick={() => history.replace('/')} />
    </div>
  );
};

export default MobileFeatureAlert;
