import i18n from 'i18next';
import i18nextXHRBackend from 'i18next-xhr-backend';
// import i18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
// import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      Contact_Type: 'Contact Type',
      Company_Name: 'Company Name',
      Person: 'Person',
      Company: 'Company',
      span: 'Span',
      Future_date_not_allowed: 'Future date not allowed',
      advised: 'Advised',
      similar_claims: 'Similar claims',
      already_added: `"{0}" already added`,
      Number: 'Number',
      Download_data_table: 'Download data table',
      Cookies_text:
        'Our website uses cookies that are necessary for site navigation, user input and security purposes. By continuing to use our website, you are agreeing to our use of cookies as described in our Privacy Policy. You can manage your cookie preferences in your browser settings.',
      Table_settings: 'Table settings',
      Toggle_columns: 'Toggle columns',
      Freeze_column: 'Freeze column',
      Something_went_wrong: 'Oops, something went wrong',
      Please_select_practitioner: 'Please, select practitioner',
      Inactive_session: 'Inactive Session',
      Inactive_session_text:
        "You've been inactive for the last {0} minutes. For your security, we will automatically log you out in {1} minute unless activity is resumed. Please save any unsaved work to avoid losing changes. Thank you for understanding.",
      Stay_connected: 'Stay Connected',
      Logout_now: 'Logout Now',
      Are_you_sure_you_want_to_delete_this_records: `Are you sure you want to delete {0} records?`,
      Are_you_sure_you_want_to_delete_this_template: `Are you sure you want to delete {0} template?`,
      Are_you_sure_you_want_to_remove_fee_codes_for_patient: `Are you sure you want to remove the fee codes {0} for patient {1}?`,
      Data_table_settings: 'Data table settings',
      Active_filters: 'Active filters',
      Options: 'Options',
      Download: 'Download',
      Download_selected: 'Download selected',
      Selection: 'Selection',
      Show_x_records: 'Show {0} record(s)',
      Filters: 'Filters',
      Sorting: 'Sorting',
      Sort: 'Sort',
      Sort_by_x: 'Sort by {0}',
      Required: 'Required',
      Thank_you_for_using_our_web_application: 'Thank you for using our web application.',
      Are_you_sure_you_want_to_delete_referral: 'Are you sure you want to delete {0} referral?',
      No_patient_selected: 'No patient selected',
      Close_this_message: 'Close this message',
      Exit_new_patient_form: 'Exit New Patient form',
      Submited: 'Submited',
      Saved: 'Saved',
      Teleplan_claim: 'Teleplan Claim',
      Private_invoice: 'Private Invoice',
      Owes: 'Owes',
      View_payments: 'View payments',
      Loading: 'Loading',
      Please_complete_patients_contact_information_first: "Please complete patient's contact information first",
      Please_provide_next_information_first: 'Please provide the following information first',
      Edit_patients_profile: "Edit patient's profile",
      Ignore_for_now: 'Ignore for now',
      Send_by_email: 'Send by email',
      Email_was_sent: 'Email was sent',
      The_invoice_for_patient_will_be_send_to_email: 'The invoice for {0} will be sent to {1}',
      Email_is_missing: "The email address is missing. Please enter it into the patient's profile before sending the invoice",
      Proceed_to_submission: 'Proceed to submission',
      Proceed: 'Proceed',
      No_Payments: 'No Payments',
      None: 'None',
      Step: 'Step',
      Anatomic_part: 'Anatomic part',
      No_PHN: 'No PHN',
      Edit_profile: 'Edit profile',
      Not_applicable: 'Not applicable',
      Finish: 'Finish',
      Add_Comments: 'Add Comments',
      Are_you_still_here: 'Are you still here?',
      Im_still_here: 'I am still here',
      Logging_out_in_x_seconds: 'Logging out in {0} seconds',
      practitioner_number_info_tooltip: 'Please enter a 5-digit number. The first character can be a letter or a digit, followed by four digits',
      Catalogs: 'Catalogs',
      Eligibility: 'Eligibility',
      Expedited: 'Expedited',
      Daysheet_uploads: 'Daysheet uploads',
      Resubmit_before: 'Resubmit before',
      Insurance_company_name: 'Insurance company name',
      Fee_item: 'Fee item',
      Billing_history: 'Billing history',
      No_ecords_in_the_last_12_months: 'No records in the last 12 months',
      Services: 'Services',
      View_claim: 'View claim',
      Show_EOB: 'Show EOB',
      Invoice_patient_for_the_balance: 'Invoice Patient for the balance',
      The_date_updated_based_on_36010: 'The service date updated based on 36010',
      Date_assist: 'Date assist',
      Suggest_date_for_36020: 'Suggest date for 36020',
      Refractory_period: 'Refractory period',
      Any: 'Any',
      Fee_Paid_cannot_be_zero: 'Amount Received cannot be zero',
      GuardianFormTitile: 'Create Guardian/Representative',
      AditionalInfo: 'Aditional Info',
      Representative: 'Representative',
      Relationship: 'Relationship',

      //Notes
      Thread: 'Thread',
      Author: 'Author',
      Adressee: 'Adressee',
      Title: 'Title',
      Note: 'Note',
      No_notes_found: 'No notes found',
      Add_New_Note: 'Add New Note',
      Edit_Note: 'Edit Note',
      Add_Note: 'Add Note',
      Search_for_notes: 'Search for notes',

      //Nav
      Dashboard: 'Dashboard',
      Overview: 'Overview',
      Schedule: 'Schedule',
      Memos: 'Memos',
      Money: 'Money',
      Invoices: {
        1: 'Invoices',
        2: 'Invoices & Claims',
        3: 'Invoice',
        create: 'Create Invoice'
      },
      Reports: 'Reports',
      Practitioners: 'Practitioners',
      Staff: 'Staff',
      Mailbox: 'Mailbox',
      User: 'User',
      Integrations: 'Integrations',
      Preferences: 'Preferences',
      Account: 'Account',
      Gear: 'Gear',
      Clinic: 'Clinic',
      Settings: 'Settings',
      Feedback: 'Feedback',
      Documentation: 'Documentation',
      'Switch back': 'Old design',
      staff: 'Personnel',
      Support: 'Support',
      Logout: 'Logout',
      Change_password: 'Change password',
      Pin_code: 'Pin code',
      Create_pin_code: 'Create PIN code',
      Edit_pin_code: 'Change PIN',
      Passwords_mismatch_error_message: 'Passwords do not match',
      New_password_cannot_be_same_as_old_password: 'New password cannot be same as old password',
      Invalid_password_format_message: 'Password requirements not met',
      Password_updated_successfully: 'Password updated successfully',
      Wrong_old_password: 'Wrong old password',
      Password_reuse_not_allowed: 'Your new password must not match any previous passwords.',
      Password_does_not_meet_complexity_requirements: 'Password is too simple',
      Billing: 'Billing',
      Insurers: 'Insurers',
      Teleplan: 'Teleplan',
      Create_Teleplan: 'Create Teleplan',
      Teleplan_POS: 'Teleplan Picker',
      Create_Teleplan_POS: 'Create Teleplan Picker',
      Picker: 'Picker',
      Group: 'Group',
      Batch: 'Batch',
      Teleplan_Batch: 'Teleplan Batch',
      Create_Teleplan_Batch: 'Create Teleplan Batch',
      Audit: 'Audit',
      Tools: 'Tools',
      'Bulk Upload': 'Bulk Upload',
      Export: 'Export',
      Practitioner_Number: 'Practitioner Number',

      // Mailbox
      This_screen_shows_the_incoming_mail: 'This screen shows the list of the files uploaded by the practitioners',

      // Practitioners
      Manage_practitioners: 'Manage practitioners',
      Practitioners_list: 'Practitioners List',
      Add_practitioner: 'Add Practitioner',
      User_Preferences: 'User Preferences',
      PrivateInvoiceDisplayOptions: 'Private invoice display options',
      Clinic_Preferences: 'Clinic Preferences',
      Delete_Practitioner: 'Delete Practitioner',
      Practitioner_is_deleted: 'Practitioner is deleted',
      Are_you_sure_delete_this_practitioner: 'Are you sure delete this practitioner',
      This_screen_shows_the_list_of_the_practitioners: `This screen shows the list of the practitioners displayed in alphabetical order. For each therapist, you can see his or her personal Name, Billing Number, Phone, and Email. To see more details, click Edit to open User Setup screen. From this screen you can also delete practitioner record or add new practitioner.`,
      Displaying_practitioners: 'Displaying {0} to {1} of {2} practitioners',
      Search_by_first_name: 'Search by first name',
      Search_by_last_name: 'Search by last name',
      Search_by_number: 'Search by number',
      Search_by_phone: 'Search by phone',
      Search_by_email: 'Search by email',
      Search_by_comment: 'Search by comment',

      //Dashboard
      Teleplan_claims: 'Teleplan claims',
      Private_invoices: 'Private invoices',
      Claims_and_Invoices: 'Claims & Invoices',
      Refresh_counters: 'Refresh counters',
      Recent_Billing: 'Recent Billing',
      Recent_Patients: 'Recent Patients',
      Please_review_your_submition: 'Please review your submition',
      You_are_about_to_submit_your_invoices_to_Teleplan: 'You are about to submit your claims to Teleplan.',
      Your_outstanding_claims_have_not_passed_our_validation:
        'Your outstanding claims have not passed our validation. It is likely they contain a future date of service. Please review your claims before trying to submit again.',
      By_practitioner: 'By practitioner',
      By_payor: 'By payor',
      By_payee: 'By payee',
      Need_corrections: 'Need corrections',
      Missing_or_invalid_PHN: 'Missing or invalid PHN',
      Click_Send_to_submit_your_invoices: 'Click Send to submit your invoices.',
      Click_Review_to_see_list_of_outstanding_invoices: 'Click Review to see list of outstanding invoices.',
      Total_invoices: 'Total invoices',
      Your_claims_are_not_ready_for_submission_yet:
        "Your claims are not ready for submission yet. Please click Create to save your claims and switch to the patients' profile to update the missing information. When ready, try to submit the claims again.",
      Missing_or_invalid_data: 'Missing or invalid data',
      Missing_or_invalid_data_in_x_profiles: 'Missing or invalid data in {0} profile(s)',
      Invalid: 'Invalid',
      Please_correct_the_missing_data_and_try_to_submit_the_claim_again: 'Please correct the missing data and try to submit the claim again.',
      Find_patient: 'Find Patient',
      Find_claim: 'Find Claim',
      Create_claim: 'Create Claim',
      Create_claim_s: 'Create Claim(s)',
      Invoice_Patient: 'Invoice Patient',
      Invoice_third_party: 'Invoice 3rd party',
      third_party: '3rd party',
      Manage_Patients: 'Manage Patient',
      Show_Affiliates: 'Login as',
      Login: 'Login',
      DataCenterNumber: 'Data Center Number',
      ConfirmSecurityConnection: 'Confirm secure connection',
      ConfirmSecurityConnectionText:
        "Access to this service requires a secure internet connection. By default, the 'Confirm secure connection' checkbox is enabled, confirming your network is private and encrypted (e.g., password-protected Wi-Fi). Do not use public or unsecured networks due to data interception risks. Unchecking this box without using VPN service will prevent login. This protocol ensures the protection of your data and our service.",
      UnconfirmSecurityConnectionText:
        "If the 'Confirm secure connection' checkbox is unchecked, indicating you are not on a secure internet connection, you must use a VPN to access this service securely. Please download the appropriate OpenVPN Connect application for your device. Once installed, follow the instructions under 'How do I connect to OpenVPN?' on the OpenVPN website. This step is crucial for ensuring your connection to our service is encrypted and secure. Access to the service will be granted upon successful connection through the OpenVPN client, ensuring your data's safety and compliance with our security policies.",
      KeepSignInText:
        "Enable 'Keep me signed in' to remain logged in when you refresh this page. Your session will persist through page reloads, if you need to refresh your browser. Please note: Closing your browser will end your session, and you will be automatically logged out due to prolonged inactivity, regardless of this checkbox. Recommended for use on personal devices only for your security.",
      KeepSignIn: 'Keep me signed in',

      //Settings
      Colors: 'Colors',
      Logotype: 'Logotype',

      // Practitioners view
      Search_for_practitioner: 'Search for practitioner',
      New_practitioner_is_created: 'New practitioner is created',

      //Patient View
      Patients: 'Patients',
      Policies: 'Policies',
      Search_for_patient: 'Search for patient',
      Add_patient: 'Add patient',
      Deceased: 'Deceased',
      DCD: 'DCD',
      LFP: 'LFP',
      Non_LFP: 'Non-LFP',
      Roster: 'Roster',
      Search_by_name: 'Search by name',
      Search_by_dob: 'Search by DOB',
      Search_by_phn: 'Search by PHN',
      Search_by_lfp: 'Search by LFP',
      Search_by_facility: 'Search by Facility',
      MRP: 'MRP',
      Search_by_mrp_number: 'Search by MRP number',
      Search_by_mrp_name: 'Search by MRP name',
      Search_by_province: 'Search by province',
      Add_new: 'Add New',
      'Find patient': 'Find patient',
      All: 'All',
      Alphabet: 'abcdefghijklmnopqrstuvwxyz',
      PHN: 'PHN',
      Name: 'Name',
      Day_of_birthday: {
        1: 'DOB',
        2: 'Birthdate',
        3: 'Date of Birth'
      },
      Search: 'Search',
      'YYYY-MM-DD': 'YYYY/MM/DD',
      Bill: 'Bill',
      Book: 'Book',
      Task: 'Task',
      Delete: 'Delete',
      Bill_Patient: 'Create Claim/Invoice',
      No_address_provided: 'No address provided',
      Delete_Patient: 'Delete Patient',
      Delete_record: 'Delete record',
      Are_you_sure_you_want_to_delete_patient: 'Are you sure yo want to delete patient {0}',
      This_profile_has_some_outstanding_claims_They_will_also_be_deleted:
        'This profile has some outstanding claims. They will also be deleted. Do you want to proceed?',
      This_record_cannot_be_deleted_because_it_has_a_billing_history:
        'This record cannot be deleted because it has a billing history. If you want to merge this record with another record, please send an email to info@claimmanager.ca and request so.',

      //Patient details
      Edit_name: 'Edit Name',
      Summary: 'Summary',
      Profile: 'Profile',
      Appointments: {
        1: 'Appointments',
        2: 'Appointment'
      },
      Payments: {
        1: 'Payments',
        2: 'Payment'
      },
      EMR: 'EMR',
      Lab: 'Lab',
      Charts: 'Charts',
      New_Note: 'New Note',
      Notes: 'Notes',
      'Search invoice': 'Search invoice',
      New_Patient: 'New Patient',
      Edit_Profile: 'Edit Profile',
      New_lab_record: 'New Lab Record',
      New_chart_record: 'New Chart Record',
      New_report: 'New Report',
      The_report_is_received: 'The report is received',
      No_report_to_display: `No report to display. Click on "{0}" button to see the report.`,

      //Patient summary
      'Primary Public Insurance': 'Primary Public Insurance',
      'Primary Practitioner': 'Primary Practitioner',
      Balance: 'Balance',
      'Last Visit': 'Last Visit',
      'Next Visit': 'Next Visit',
      Check_MSP: 'Check MSP',
      See_MSP_Eligibility_Report: 'See MSP Eligibility Report',
      Check_MSP_Eligibility: 'Check MSP Eligibility',
      Update_MSP_Eligibility: 'Update MSP Eligibility',
      Check_Eligibility_only: 'Check eligibility only',
      Check_MSP_Spelling: 'Check MSP Spelling',
      Check_MSP_Spelling_and_Eligibility: 'Check MSP name & eligibility',
      Name_and_eligibility: 'Name + Eligibility',
      Request_patient_information_from_Teleplan: "Request patient's information from Teleplan",

      //Patient info
      'years old': 'y.o.',
      Gender: 'Gender',
      Male: {
        1: 'M',
        2: 'Male'
      },
      Female: {
        1: 'F',
        2: 'Female'
      },
      First_Name: 'First Name',
      Last_Name: 'Last Name',
      Nickname: 'Nickname',
      Prescriber_First_Name: "Prescriber's First Name",
      Prescriber_Last_Name: "Prescriber's Last Name",
      Middle_Name: 'Middle Name',
      Middle_Initial: 'Middle Initial',
      Email: 'Email',
      'Email Type': 'Email Type',
      Phone: 'Phone',
      'Phone Type': 'Phone Type',
      'Remove phone number': 'Remove phone number',
      'Add phone number': 'Add phone number',
      Address: 'Address',
      'Street Address': 'Street Address',
      Address_Line: 'Address Line',
      City: 'City',
      Field: 'Specialization',
      Demographics: 'Demographics',
      MSP_Check: 'MSP Check',
      Contact_Info: 'Contact Info',
      Specialties_And_Billing: 'Specialties and Billing',
      Province: 'Province',
      'Postal Code': 'Postal Code',
      Address_Type: 'Address Type',
      Create_Record: 'Create Record',
      Please_enter_valid_email: 'Please, enter valid email',
      Enter_PHN_and_DOB: 'Eligibility check is only available for B.C. patients. Both PHN and Birthdate are required.',
      No_email_or_phone_number: 'No email or phone number',
      No_address: 'No address',
      MSP_Eligibility: 'MSP Eligibility',
      MSP_Eligibility_Report: 'MSP Eligibility Report',
      MSP_eligibility_is_limited_to_BC_patients_only: 'MSP eligibility is limited to B.C. patients only.',

      // Eligibility message
      Active_on: 'Active on',
      Inactive_on: 'Inactive on',
      Undetermined_on: 'Undetermined on',
      Eye_Exam: 'Eye Exam',
      No_eligibility_report_is_available: 'No Eligibility report is available',

      //PatientNotes
      Add: 'Add',
      Date: 'Date',
      Confirmation: 'Confirmation',
      Are_you_sure: 'Are you sure you want to delete note?',
      Yes: 'Yes',
      No: 'No',

      //Create patient
      Create_new_patient: 'Create new patient',
      Open_patients_profile: "Open patient's profile",
      Create_another_patient: 'Create another patient',
      You_created_profile_for: 'You created profile for',
      Coverage_eligibility_details: 'Coverage Eligibility Details',
      Patient_gender: 'Patient Gender',
      Patient_restriction: 'Patient Restriction',
      Last_Name_is_required: 'Last Name is required.',
      First_Name_is_required: 'First Name is required.',
      Gender_is_required: 'Gender is required.',
      Invalid_email_address: 'Invalid email address. E.g. example@email.com.',
      Birthdate_is_required: 'Birthdate is required.',
      PHN_must_be_at_most_characters: 'PHN must be at most 10 characters.',
      Wrong_spelling_can_cause_declined_claims:
        'Wrong spelling can cause declined claims. For MSP insured patients, please verify the name with the patient or by calling the 24-hour automated line.',
      MSP_eligibility_report_seems_to_contain_different_patients_demographics:
        "MSP eligibility report seems to contain different patient's demographics.",
      Eligibility_report_might_contain_obsolete_demographics_data:
        "Eligibility report might contain obsolete demographics data. Wrong patient's demographics might cause declined claims. Please verify the data with the patient or by calling 24-hour automated MSP line",
      Eligibility_report: 'Eligibility report',
      Your_records: 'Your records',
      Ignore: 'Ignore',
      Use_eligibility_demographics: 'Use Eligibility Demographics',
      Name_Mismatch: 'Name Mismatch',
      Match_Found: 'Match Found',
      MSP_Spelling_Report: 'MSP Spelling Report',
      MSP_Spelling: 'MSP Spelling',
      Vancouver: 'Vancouver',
      Victoria: 'Victoria',
      or: 'or',
      BC_toll_free: 'BC toll-free',
      Enter: 'Enter',
      Zip: 'Zip',
      Postal_Code: 'Postal Code',
      FacilityName: 'Facility Name',
      Enter_PHN_MM_YYYY: 'Enter: {0}, {1}, PHN, MM, YYYY, {2} (MM and YYYY - month and year of birth)',
      Fields_are_already_filled_in:
        'First name, last name, middle name or gender fields are already filled in. Do you want to replace them with new data?',
      Coverage_ened_on: 'Coverage ened on {0} because {1}',
      MSP_visits_paid_to_date: 'MSP visits paid-to-date: {0} out of 10',
      Premium_assistance_status: 'Premium Assistance Status',
      Patient_Restriction: 'Patient Restriction',
      Message: 'Message',
      Date_of_last_eye_exam: 'Date of last eye exam',
      No_patient_restrictions: 'No patient restrictions',
      MSP_Eligibility_returns_the_following_name:
        'MSP Eligibility returns the following name for this patient: {0}. It may or may not be correct. Please use your judgement when entering the name.',
      Do_you_want_to_add_the_patient_to_your_teleplan_claim: 'Do you want to add the patient to your Teleplan claim?',
      Whether_or_not_the_patient_has_an_active_coverage_with_Medical_Service_Plan:
        'Whether or not the patient has an active coverage with Medical Service Plan of B.C.',
      Whether_or_not_MSP_might_cover_towards_the_first_ten_visits_in_a_calendar_year:
        'Whether or not MSP might cover $23.00 towards the first ten visits in a calendar year to an acupuncture, massage, physiotherapy or chiropractic clinic.',
      Last_covered_eye_exam_as_reported_by_MSP_eligibility_system: 'Last covered eye exam as reported by MSP eligibility system',

      // Teleplan Report
      OOFHP: 'OOFHP',
      Edit_Teleplan_Invoice: 'Edit Teleplan Invoice',
      Create_Teleplan_Invoice: 'Create Teleplan Invoice',
      One_of_the_selected_fee_codes_might_qualify_for_out_of_office_premiums:
        'One of the selected fee codes might qualify for out-of-office premiums that are payable on a weekend, holiday or after business hours.  If you want to claim those codes, please use another Create Teleplan screen',
      You_may_qualify_for_the_out_of_office_premiums:
        'You may qualify for out-of-office hours premiums (OOFHP) if it was an emergency service. To apply for OOFHP, go back and set the OOFHP/Call Out/CCFPP switch to Yes. The program will add the OOFHP codes automatically if applicable.',
      Applies_only_to_the_anesthetic_procedural_fee:
        "01080 is a multiplier and applies only to the anesthetic procedural fee [including routine P.A.R. care]. Please enter the procedural code to ensure that amount for 01080 is calculated correctly. You can delete the procedural code on the 2nd step. If you don't select the code, we will apply the max amount to 01080.",
      Please_select_anatomic_code_if_applicable: 'Please select anatomic code if applicable.',
      Do_you_want_to_replace_consultation_code: 'Do you want to replace {0} with {1}?',
      Please_fill_required_dx_fields: 'Please complete the Dx codes',
      Please_fill_required_fee_fields: 'Please complete the Fee codes',
      Please_fill_required_referral_fields: 'Please complete the Referral codes',
      Please_fill_required_comment_fields: 'Please complete the Comment fields',
      Please_fill_required_time_fields: 'Please fill required time fields',
      Please_fill_required_fields: 'Please fill required fields',
      We_will_pre_fill_the_patients_list_using_the_selected_template:
        "We will pre-fill the patient's list using the selected Template. Any patients already entered into the field will be removed. You can add them at the end of the Template's list.",
      Delete_group_record: 'Delete record for {0}, {1}',
      Duplicate_group_record: 'Duplicate record for {0}, {1}',
      No_fee_code: 'No fee code',
      The_code_has_already_been_added: 'The code {0} has already been added',
      Invalid_ICBC:
        'ICBC claim number is 8 characters long with the first character being a letter (A-Z) and the next 7 being numbers (0-9), or two letters (A-Z) and the next 6 being numbers (0-9). Blank number is valid, but the claim may be declined.',
      The_fee_code_xxx_requires_longer_duration:
        'The fee code(s) {0} requires longer duration than what you entered. Please correct the start and end time to meet the requirements or select a different service code',
      Fee_code_X_must_be_Y_hour: 'Fee code {0} must be {1}. Please correct duration.',
      Code_X_min_duration_Y_or_major_portion: 'Code {0}: Min duration - {1} or major portion thereof ({2}). Please correct the duration.',
      Code_X_min_duration_Y: 'Code {0}: Min duration - {1}. Please correct the duration.',
      Code_X_max_duration_Y: 'Code {0}: Max duration - {1}. Please correct the duration.',
      Code_X_min_duration_Y_suitable_code_P:
        'Code {0}: Min duration - {1} min. Please change the duration or select another code that matches the duration. The suitable code is {2}.',
      Code_X_max_duration_Y_suitable_code_P:
        'Code {0}: Max duration - {1} min. Please change the duration or select another code that matches the duration. The suitable code is {2}.',
      Code_X_min_units: 'Code {0}: Min units - 1. Please correct the units/duration.',
      Code_X_max_units: 'Code {0}: Max units - {1}. Please correct the units/duration.',
      Please_call_WSBC_support_line_to_ensure: 'Please call WSBC support line to ensure that this claim can be rebilled',
      Code_13003_is_calculated_as:
        'Code 13003 is calculated as 25% of the 00193/5/6/7. Please select that base code to enable the calculation. You can delete the base code on the next step if you already submitted it',
      Please_use_a_different_Create_Teleplan_screen_to_bill_13003: `Please use a different "Create Teleplan" screen to bill 13003 and its associated codes because it requires calculation and special treatment`,
      See_patients_billing_history: "See patient's billing history",
      Select_patient: 'Select patient',
      Past_claims: 'Past claims',

      // Teleplan group
      One_of_the_claims_resulted_in_zero_units:
        'One of the claims resulted in zero units. Please increase the duration, correct the units or delete the claim',
      Description: 'Description',
      Template_saved_successfully: 'The {0} template saved successfully',
      Batch_Templates: 'Batch Templates',
      Consecutive_time: 'Consecutive time',
      Arrows_reorder: 'Arrows reorder',
      Drag_drop_reorder: 'Drag and Drop Reorder',
      Switch_on_to_enter_consecutive_claims: 'Switch on to enter consecutive claims',
      Switch_on_to_show_arrows_reorder: 'Toggle this switch to show or hide the arrows that allow reordering rows in the table',
      Switch_on_to_show_drag_drop_reorder: 'Toggle this switch to enable or disable drag-and-drop reordering for rows in the table',
      Save_group_for_future_billing: 'Save group for future billing',
      Such_a_group_already_exists: "Such a group already exists. It is called '{0}'. Do you want to save its duplicate?",
      Use_catalog_icon_in_the_fee_item: 'Use Catalog icon in the Fee Item(s) field to select multiple fee codes for each patient',
      Set_the_fee_codes_for_all_patients_in_the_batch: 'Set the fee codes for all patients in the batch before you start selecting the time',
      Select_all_the_codes_tha_you_want_to_submit_for_this_patient: 'Select all the codes that you want to submit for this patient',
      Choose_the_codes_in_the_order_the_services_were_provided: 'Choose the codes in the order the services were provided if the order is important',

      // eClaim
      eClaim: 'eClaim',
      Create_eClaim: 'Create eClaim',
      Edit_eClaim: 'Edit eClaim',
      View_eClaim: 'View eClaim',
      eClaim_Policy: 'eClaim Policy',
      Create_eClaim_Policy: 'Create eClaim Policy',
      Policy_Number: 'Policy Number',
      Secondary: 'Secondary',
      Secondary_Coverage: 'Secondary Coverage',
      Member_ID: 'Member ID',
      Benefit_Type: 'Benefit Type',
      Insured_Member: 'Insured Member',
      Birth_Order: 'Birth Order',
      Insured_Members_Name: "Insured Member's Name",
      Patient_is_his_her: 'Patient is his/her/their',
      Additional_ID: 'Additional ID',
      Extended_Health: 'Extended Health',
      Insurance_Company: 'Insurance Company',
      Relative: 'Relative',
      Spouse: 'Spouse',
      Full_time_student: 'Full-time student',
      Part_time_student: 'Part-time student',
      Family_dependent: 'Family dependent',
      Handicapped_dependent: 'Handicapped dependent',
      Domestic_partner: 'Domestic partner',
      Insurance_Information: 'Insurance Information',
      Primary_Insurance: 'Primary Insurance',
      Primary: 'Primary',
      Create_Insurance_Policy: 'Create Insurance Policy',
      Edit_Insurance_Policy: 'Edit Insurance Policy',
      Insured_Members_name_as_shown_on_the_card: "Insured Member's name as shown on the card",
      Who_is_the_insured_member_on_this_plan: 'Who is the insured member on this plan',
      Change_the_name_to_match_the_name_on_the_insurance_card: 'Change the name to match the name on the insurance card',
      You_recorded_new_insurance_policy_for: 'You recorded new insurance policy for',
      You_edited_insurance_policy_for: 'You edited insurance policy for',
      Primary_insurance_policy: 'Primary insurance policy',
      Select_the_primary_insurance_policy: 'Select the primary insurance policy',
      Select_the_secondary_insurance_policy: 'Select the secondary insurance policy',
      Secondary_insurance_policy: 'Secondary insurance policy',
      Please_refer_to_the_Telus: 'Please refer to the TELUS health eClaims Tutorial for more information about the',
      coordination_of_benefits: 'coordination of benefits',
      Add_New_Policy: 'Add New Policy',
      Primary_Insurance_Policy: 'Primary Insurance Policy',
      Secondary_Insuranc_Policy: 'Secondary Insurance Policy',
      No_policy_is_entered_for_this_patient: 'No policy is entered for this patient',
      No_policies_found: 'No policies found',
      Accident_Type: 'Accident Type',
      Accident_Date: 'Accident Date',
      Prescriber_Type: 'Prescriber Type',
      Workplace: 'Workplace',
      Motor_Vehicle: 'Motor Vehicle',
      School: 'School',
      Sporting: 'Sporting',
      Physician: 'Physician',
      Optometrist: 'Optometrist',
      Ophthalmologist: 'Ophthalmologist',
      accident_type_info_tooltip:
        'If the treatment was given to the patient as a result of an accident please enter the type and the date of the accident',
      prescriber_type_info_tooltip:
        'If the treatment was given as a result of a prescription or a referral please enter the information about the healthcare professional that prescribed the service(s) or made the referral',
      Authorization_Form: 'Authorization Form',
      I_accept_the: 'I ACCEPT the',
      Terms_and_Conditions: 'Terms & Conditions',
      Signed: 'Signed',
      Less_MSP: 'Less MSP',
      Delete_service: 'Delete Service',
      Complete_setup: 'Complete setup',
      Please_complete_setup: 'Please complete setup',
      Please_complete_setup_or_contact_us:
        'Practitioner {0} has not completed eClaims integration set up in Claim Manager. Please have them contact Claim Manager support for assistance with the set up. No claims can be submitted until then.',
      Insurance: 'Insurance',
      Service: 'Service',
      Response: 'Response',
      Invoice_patient: 'Invoice Patient',

      // WSBC Report
      Form: 'Form',
      Month: 'Month',
      Months: 'Months',
      Days: 'Days',
      At_work: 'At work',
      WSBC_Report: 'WSBC Report',
      New_WSBC_Report: 'New WSBC Report',
      Edit_WSBC_Report: 'Edit WSBC Report',
      Rebill_WSBC_Report: 'Rebill WSBC Report',
      Create_WSBC_Report: 'Create WSBC Report',
      Claim_Info: 'Claim Info',
      General: 'General',
      FormType: 'Form Type',
      Diagnostic_Code: 'Diagnostic Code',
      Diagnosis: 'Diagnosis',
      Prior: 'Prior/Others',
      Injury: 'Injury',
      Clinical: 'Clinical',
      Fee_Items: 'Fee Item(s)',
      Return_To_Work: 'Return-to-work',
      Service_Details: 'Service Details',
      First_Report_8: 'First Report (8)',
      Conditions_or_Treatment_Has_Changed_11: 'Conditions or Treatment Has Changed (11)',
      Employers_Name: "Employer's Name",
      Employers_Phone: "Employer's Phone",
      Operating_Location_Address: 'Operating Location Address',
      Employers_telephone_number: "Employer's Telephone Number",
      WCB_claim_number: 'WCB Claim Number',
      Workers_last_name: "Worker's Last Name",
      Telephone_number: 'Telephone Number',
      Workers_phn_from_health_card: "Worker's PHN from Health Card",
      Submit_With_Generic_PHN: 'Submit With Generic PHN',
      Create_Report: 'Create Report',
      Check_off_this_box_if_the_patient_is_not_eligible:
        'Check off this box if the patient is not eligible for MSP coverage (invalid, expired or absent PHN). The WSBC invoice/report will be submitted with a default generic PHN that is required for such patients. If you choose not to select this check box, the invoice/report will be declined.',
      Are_you_the_workers_regular_primary_practitioner: "Are you the worker's regular primary practitioner?",
      Are_you_the_workers_regularphysician: "Are you the worker's regular physician?",
      If_Yes_how_long_has_the_worker_been_your_patient: 'If Yes, how long has the worker been your patient?',
      Who_rendered_the_first_treatment: 'Who rendered the first treatment?',
      How_long_has_the_worker_been_your_patient: 'How long has the worker been your patient?',
      Who_rendered_the_first_treatment_for_this_injury: 'Who rendered the first treatment for this injury?',
      Prior_Other_Problems_Affecting_Injury_Recovery_and_Disability: 'Prior/other problems affecting injury, recovery, and disability',
      From_injury_or_since_last_report: 'From injury or last report, has the worker been disabled from work',
      If_no_what_are_the_current_physical_and_or_psychological_restrictions:
        'If NO, what are the current physical and/or psychological restrictions?',
      What_happened_subject_sx_examination: 'What happened? Subject Sx, examination, investigations, treatments/meds, specialists consult?',
      If_Yes_as_of_what_date_if_known: 'If Yes, as of what date? (if known)',
      If_Yes_as_of_what_date: 'If Yes, as of what date?',
      Clinical_information: 'Clinical Information',
      Clinical_Information_Describe:
        'Describe: What happened, Subjective Symptoms, Examination Findings, Investigations, Treatment, Meds and Specialist Consults',
      Is_the_worker_now_medically_capable_of_working_full_time: 'Is the worker now medically capable of working full duties, full time?',
      If_No_what_are_the_current_physical_and_psychological_restrictions: 'If No: What are the current physical and/or psychological restrictions?',
      What_are_the_current_restrictions: 'If No: What are the current restrictions?',
      Estimated_time_before_the_worker_will_be_able_to_return:
        'Estimated time before the worker will be able to return to the workplace in any capacity',
      Is_the_worker_now_ready_for_a_rehabilitation_program: 'If appropriate, is the worker now ready for a rehabilitation program?',
      Work_Conditioning_Program: 'Work Conditioning Program',
      Do_you_wish_to_consult_with: {
        1: 'Do you wish to consult with Chiropractic Consultant, WCB physician or nurse advisor?',
        2: 'Do you wish to consult with a WorkSafeBC physician or nurse advisor?'
      },
      Estimate_date_of_Maximal_Medical_Recovery: 'If possible, estimate date of Maximal Medical Recovery',
      Billing_information: 'Billing Information',
      Date_of_service: 'Date of Service',
      Fee_item_code: 'Fee Item Code',
      Fee_item_description: 'Fee Item Description',
      Submitted: 'Submitted',
      Sequential_No: 'Sequential No.',
      Amount_billed: 'Amount Billed',
      Amount_paid: 'Amount Paid',
      Injury_codes_and_description: 'Injury Codes and Descriptions',
      Injury_codes: 'Injury Codes',
      Workers_mailing_address: "Worker's Mailing Address",
      Mailing_address: 'Mailing Address',
      Workers_contact_telephone_number: "Worker's contact telephone number",
      CSA_BP_AP: 'CSA BP/AP',
      Physicians_report: "Physician's Report",
      Chiropractors_report: "Chiropractor's Report",
      WorkSafeBC_report: 'WorkSafeBC Report',
      You_saved_WSBC_report_for_: 'You {0} WorkSafeBC report {1} for {2} - {3}',
      Employers_name: "Employer's name",
      Workers_personal_health_number: "Worker's personal health number",
      Payee_number: {
        1: 'Payee number',
        2: 'Payee Number'
      },
      Payee_name: 'Payee name',
      Practitioner_number: 'Practitioner number',
      Practitioner_name: 'Practitioner name',
      The_date_is_earlier_than_injury_date: 'The date is earlier than injury date',
      Dont_show_this_again_today: "Don't show this again today",
      Maximal_medical_recovery: 'Maximal medical recovery',
      The_x_date_is_earlier_than_injury_date: 'The {0} date is earlier than injury date',

      // Receive Payment
      Payment_Date: 'Payment Date',
      Payment_Method: 'Payment Method',
      Last_Digits_Of_Credit_Card: "Last digits of payer's credit card or reference #",
      Amount_Received: 'Amount Received',
      Cash: 'Cash',
      Visa_Credit: 'Visa Credit',
      MasterCard_Credit: 'MasterCard Credit',
      AmEx_Credit: 'AmEx Credit',
      Debit_Card_Interac: 'Debit Card (Interac)',
      Visa_Debit: 'Visa Debit',
      MasterCard_Debit: 'MasterCard Debit',
      AmEx_Debit: 'AmEx Debit',
      Check: 'Check',
      for: 'for',
      You_have_created_a_payment_for: 'You have {0} a payment for {1} for {2}',

      //common -> NewPayorFormLayout - already defined
      //Company_Name: 'Company Name',

      //common -> contants
      Mailing: 'Mailing',
      Actual: 'Actual',
      Residence: 'Residence',
      Personal: 'Personal',
      Business: 'Business',
      Other: 'Other',
      Cell: 'Cell',
      Home: 'Home',

      //common buttons
      Ok: 'OK',
      View_More: 'View More',
      More_options: 'More options',
      Open_Catalog: 'Open Catalog',
      Close_Catalog: 'Close Catalog',
      Back: 'Back',
      Print: 'Print',
      Revoke: 'Revoke',
      Save_as_pdf: 'Save as PDF',
      Send: 'Send',
      Share_Link: 'Share Link',
      Generate_Invoices: 'View Invoices',
      Edit: 'Edit',
      Rebill: 'Rebill',
      Cancel: 'Cancel',
      Save_and_Close: 'Save & Close',
      Save_and_Submit: 'Save & Submit',
      Save_and_Claim: 'Save & Claim',
      Create: 'Create',
      Create_Template: 'Create Template',
      Edit_Template: 'Edit Template',
      Review_and_Submit: 'Review & Submit',
      Save_and_Review: 'Save & Review',
      Submit_and_Close: 'Submit & Close',
      Replace_code: 'Replace code',
      Replace_all_codes: 'Replace all code(s)',
      Close: 'Close',
      View: 'View',
      Carousel: 'Carousel',
      Claim_profile: 'Claim profile',
      Reset: 'Reset',
      New_claim: 'New Claim',
      Add_new_patient: 'Add new patient',
      Add_new_practitioner: 'Add new practitioner',
      Add_new_referral_practitioner: 'Add new referral practitioner',
      Add_new_payor: 'Add new payor',
      Patients_profile: 'Patient profile',
      Clear_claim_data: 'Clear claim data',
      Create_New: 'Create New',
      Modify_Selected: 'Modify Selected',
      Create_New_Claim: 'Create New Claim',
      Modify_Selected_Claim: 'Modify Selected Claim',
      Debit: 'Debit',
      Codes: 'Codes',
      Create_Invoice: 'Create Invoice',
      Paid_with_adjustment: 'Paid with adjustment',
      Clear_all: 'Clear all',
      Next: 'Next',
      Multiple: 'Multiple',
      Multiple_practitioners: 'Multiple practitioners',
      All_practitioners: 'All practitioners',
      Multiple_services: 'Multiple services',
      Click_to_add_practitioner: 'Click to add practitioner',
      Click_to_add_note: 'Click to add note',
      Ignore_similar_records: 'Ignore similar records',
      Correct_PHN: 'Correct PHN',
      Submit_valid_claims: 'Submit valid claims',
      Update_PHN: 'Update PHN',
      Exit: 'Exit',
      Receive_Payment: 'Record Payment',
      Email_Invoice: 'Email Invoice',
      Edit_Payment: 'Edit Payment',
      New_Invoice: 'New Invoice',
      First_Visit: 'First Visit',
      Paid_in_full: 'Paid in full',
      Add_New: 'Add New',
      Go_back: 'Go Back',
      New_policy: 'New policy',
      Ignore_and_Proceed: 'Ignore and Proceed',
      Move_to_draft_and_submit_the_rest: 'Move to draft and submit the rest',

      //common errors
      Mandatory: 'Mandatory',
      Mandatory_field: {
        1: 'Mandatory field',
        2: 'Mandatory field (tap to edit)'
      },
      Missing_city: 'Missing city',
      Missing_address: 'Missing address',
      This_field_is_requred: 'This field is requred',
      No_results_found: 'No results found',
      Invalid_entry: 'Invalid entry',
      Invalid_PHN: 'Invalid PHN',
      Invalid_date_format: 'Invalid date. Use mm/dd/yyyy format',
      Please_correct_invalid_entries: 'Please correct invalid entries.',
      Limit_one_patient: 'Limit one patient',
      Limit_x_patient: 'Limit {0} patient(s)',
      You_selected_too_many_patients: 'You selected too many patients. Please remove all patients except one.',
      The_message_must_not_be_longer_than_X_characters: 'The message must not be longer than {0} characters.',
      Please_create_a_claim_for_03333_code_separately: 'Please create a claim for 03333 code separately.',
      You_have_successfully_submitted_x_claims_for_y: 'You have successfully submitted {0} claims for {1}',
      You_have_successfully_submitted_x_claims_for_y_z_records_did_not_pass:
        'You have successfully submitted {0} claims for {1}. {2} records did not pass validation and were marked as Drafts',
      Debit_request_success: 'Debit request success',
      Year_minimum_value_is: 'Year minimum value is',
      Year_maximum_value_is: 'Year maximum value is',
      Maximum_date_value_is: 'Maximum date value is',
      Practitioner_number_minimum_value_is: 'Practitioner number minimum value is',
      Practitioner_number_maximum_value_is: 'Practitioner number maximum value is',
      Wrong_fee_item_code: "The {0} code(s) does not match the patient's age",
      End_time_must_be_greater_than_or_equal_to_start_time: 'End time must be greater than or equal to start time',
      bmi_must_be_at_least: 'BMI must be at least {0}',
      bmi_must_be_at_most: 'BMI must be at most 99.9',
      bmi_must_be_at_least_and_at_most: 'BMI must be at least {0} and at most 99.0',
      At_least_1: 'At least 1',
      Invalid_number: 'Invalid number',
      Please_enter_the_code_you_received_in_the_email: 'This code is either invalid or expired. Please click Cancel and try again.',
      Patients_age_doesnt_match_must_be_18_years_or_older: 'Age must be 18+',
      Patients_age_doesnt_match_must_be_75_years_or_older: 'Age must be 75+',
      Patients_age_doesnt_match_must_be_from_1_to_17_years_old: 'Age must be 17 and under',
      This_name_already_exists: 'This name already exists',

      //common text
      HH_MM: 'HH:MM',
      MM_DD_YYYY: 'MM/DD/YYYY',
      Based_on_Declined_Claim: 'Based on Declined Claim',
      Based_on_Accepted_Claim: 'Based on Previously Paid Claim',
      Based_on_In_Process_Claim: 'Based on In Process Claim',
      No_MSP_coverage_report: 'No MSP coverage report',
      Please_select_a_category: 'Please select a category',
      Please_select_a_category_on_the_left: 'Please select a category on the left',
      No_recent_codes: 'No recent codes',
      Whats_your_next_step: "What's your next step?",
      Drag_and_drop_files_to_here_to_upload: 'Drag and drop files to here to upload.',
      Licence: 'Licence',
      by: 'by',
      Duplicate_records_alert: 'Duplicate records alert',
      You_have_similar_records_in_the_system: 'You already have similar records. What do you want to do?',
      Use: 'Use',
      Open: 'Open',
      Restore: 'Restore',
      Catalog: 'Catalog',
      Restore_and_use: 'Restore and use',
      Update_and_Use: 'Update and Use',
      Merge_records: 'Merge records',
      Restore_and_merge: 'Restore and merge',
      The_demographics_in_previous_record_will_be_replaced_with_the_current_values:
        'The demographics in previous record will be replaced with the current values.',
      Note_that_the_existing_record_has_a_different_PHN: `Note that the existing record has a different PHN. Select "Ignore" if you want to submit claims with a new PHN.`,
      Duplicate_record_alert: 'Duplicate record alert',
      The_record_with_PHN_already_exists: 'The record with PHN {0} already exists.',
      A_profile_with_PHN_already_exists: 'A profile with PHN {0} already exists. It is {1}, DOB {2}. What do you want to do?',
      Open_existing_profile: 'Open existing profile',
      Use_existing_profile: 'Use existing profile',
      Amount_applied: 'Amount applied',
      made_on: 'made on',
      Do_you_want_to_delete_this_record: 'Do you want to delete this record?',
      The_record_was_successfully_deleted: 'The record was successfully deleted',
      Add_address: 'Add address',
      Please_select_practitioner_on_the_left_hand_panel_to_see_the_services: 'Please select practitioner on the left-hand panel to see the services',
      Age_mismatch: 'Age mismatch',
      This_record_was_restored_from_archived: 'This record was restored from archived',
      Add_service: 'Add Service',
      Please_add_service_or_product_to_create_the_invoice: 'Please add service or product to create the invoice',
      Preview_the_claims_before_submitting_them: 'Preview the claims before submitting them',
      Multiple_Dates: 'Multiple Dates',
      Service_Dates: 'Service Dates',

      //common tooltips
      Click_Esc_then_Tab_keys_to_move_to_the_next_field: 'Click Tab, then Esc keys to move to the next field',
      Add_new_invoice: 'Add new invoice',
      Delete_claim: 'Delete claim',
      Submit_all_outstanding_claims: 'Submit all outstanding claims to Teleplan',
      Select_service: 'Select service',
      Only_claims_with_valid_and_complete_PHNs_will_be_submitted: 'Only claims with valid and complete PHNs will be submitted',
      View_patients_whose_PHNs_need_correction: 'View patients whose PHNs need correction',
      View_all_ready_to_submit_claims: 'View all ready-to-submit claims',
      Click_on_service_product_to_add_to_the_invoice: 'Click on service/product to add to the invoice',

      //TabbedDetails
      'Edit in progress': 'Edit in progress',
      'Please Save your changes or Cancel': 'Please Save your changes or Cancel',

      //provinces_list
      Newfoundland_and_Labrador: 'Newfoundland and Labrador',
      Prince_Edward_Island: 'Prince Edward Island',
      Nova_Scotia: 'Nova Scotia',
      New_Brunswick: 'New Brunswick',
      Quebec: 'Quebec',
      Ontario: 'Ontario',
      Manitoba: 'Manitoba',
      Saskatchewan: 'Saskatchewan',
      Alberta: 'Alberta',
      British_Columbia: 'British Columbia',
      Yukon: 'Yukon',
      Northwest_Territories: 'Northwest Territories',
      Nunavut: 'Nunavut',
      Institutional: 'Institutional',

      // mapper
      M: 'M',
      W: 'W',
      I: 'I',
      P: 'P',
      E: 'E',
      'All time': 'All time',
      'This year': 'This year',
      'Previous calendar year': 'Previous calendar year',
      'Last 3 months': 'Last 3 months',
      'Last 6 months': 'Last 6 months',
      Future: 'Future',
      'Custom period': 'Custom period',
      Single_date: 'Single date',
      All_Invoices_and_Claims: 'All Invoices & Claims',
      'All Claims': 'All Claims',
      'All Teleplan': 'All Teleplan',
      'All Private': 'All Private',
      'All Invoices': 'All Invoices',
      'All Extended': 'All Extended',
      eClaims_and_PBC: 'eClaims & PBC',
      MSP: 'MSP',
      ICBC: 'ICBC',
      WSBC: 'WSBC',
      eClaims: 'eClaims',
      WorkSafeBC: 'WorkSafeBC',
      Private_Uninsured_Patient_Pay: 'Private (Uninsured, Patient Pay)',
      'Private_Uninsured_third_party_pay)': 'Private (Uninsured, third party pay)',
      'TELUS Health': 'TELUS Health',
      'Other Extended': 'Other Extended',
      'All Statuses': 'All Statuses',
      Outstanding: 'Outstanding',
      'In Process': 'In Process',
      In_Process_Debit: 'In Process Debit',
      On_Hold: 'On Hold',
      Accepted: 'Accepted',
      Rebilled: 'Rebilled',
      Declined: 'Declined',
      'Not Paid': 'Not Paid',
      Draft: 'Draft',
      Archived: 'Archived',
      Archive: 'Archive',
      Adjusted: 'Adjusted',
      Reduced: 'Reduced',
      'DR User': 'DR User',
      'DR Teleplan': 'DR Teleplan',
      Acknowledgment: 'Acknowledgment',
      Issued: 'Issued',
      Paid: 'Paid',
      New: 'New',
      Processed: 'Processed',
      Success: 'Success',
      Error: 'Error',
      Info: 'Info',
      //"Practitioner_Office_In_Community": "Practitioner's Office - In Community [A]",
      Residential_Care: 'C - Residential Care / Assisting Living Residance',
      Diagnostic_Facility: 'D - Diagnostic Facility',
      Hospital_Emergency_Room: 'E - Hospital-Emergency Room',
      Hospital_Inpatient: 'I - Hospital-Inpatient',
      Hospital_Outpatient: 'P - Hospital-Outpatient',
      Patient_Private_Home: "R - Patient's Private Home",
      Other_Z: 'Z - Other (i.e. accident site or in an ambulance)',
      Mental_Health_Center: 'M - Mental Health Center',
      Practitioner_Office_Publicly_Administered: "T - Practitioner's Office in Publicly Administered",
      Hospital_Day_Care: 'G - Hospital-Day Care (Surgery)',
      Private_Medical_Surgical_Facility: 'F - Private Medical / Surgical Facility',
      Community_Health_Center: 'B - Community Health Centre',
      FN_Primary_Clinic: 'J - First Nations Primary Health Care Clinic',
      Hybrid_Primary_Care: 'K - Hybrid Primary Care Practice',
      Longitudinal_Primary_Care: 'L - Longitudinal Primary Care Practice',
      Practitioner_Office_NonPhysician: 'N - Health Care Practitioner Office (non-physician)',
      Specialist_Physician_Office: 'Q - Specialist Physician Office',
      'Urgent_and_Primary_Care _Centre': 'U - Urgent and Primary Care Centre',
      Virtual_Care_Clinic: 'V - Virtual Care Clinic',
      Walk_In_Clinic: 'W - Walk-In Clinic',
      Practitioners_Office: "Practitioner's Office",
      Speciality: 'Speciality',
      //submissionCodes
      Normal_Submission: {
        1: 'Normal Submission [O]',
        2: 'Normal Submission'
      },
      ICBC_Claim: {
        1: 'ICBC Claim [I]',
        2: 'ICBC Claim'
      },
      Declined_WSBC_Claim: {
        1: 'Declined WSBC Claim [W]',
        2: 'Declined WSBC Claim'
      },
      'Over-age_Claim': {
        1: 'Over-age Claim [A]',
        2: 'Over-age Claim'
      },
      Declined_Claim: {
        1: 'Declined Claim [X]',
        2: 'Declined Claim'
      },
      Coverage_Problem: {
        1: 'Coverage Problem [C]',
        2: 'Coverage Problem'
      },
      Duplicate_Claim: {
        1: 'Duplicate Claim [D]',
        2: 'Duplicate Claim'
      },
      'Re-Submitted_Claim': {
        1: 'Re-Submitted Claim [R]',
        2: 'Re-Submitted Claim'
      },

      //RowExpantionTable
      Date_of_injury: {
        1: 'DOI',
        2: 'Date of Injury'
      },
      Office: {
        1: 'Off',
        2: 'Office',
        3: 'Office Number'
      },
      Sequence: {
        1: 'Seq',
        2: 'Sequence'
      },
      Submission: 'Sent',
      Ref: 'Ref.',
      Remittance: 'Remittance',
      'More codes': 'More codes',
      'Converted from MSP claim on': 'Converted from MSP claim on',
      History: 'History',
      Seen_by: 'Seen by',
      Policy: 'Policy',
      Plan: 'Plan',
      Referred: {
        1: 'Referred',
        2: 'Ref'
      },
      Printed: 'Printed',
      Emailed: 'Emailed',

      //RowExpantionTemplate
      Anatomic_Area_Code: 'AAC',
      Amount: 'Amount',
      Time: 'Time',

      //ClaimDetails
      Invoice_Preview: 'Invoice Preview',
      Create_private_invoice: 'Create Invoice',
      Edit_private_invoice: 'Edit Invoice',
      Create_teleptan_claim: 'Create Teleplan Claim',
      'Service Details': 'Service Details',
      Submit: 'Submit',
      Save: 'Save',
      Duplicate: 'Duplicate',
      'Generate Records': 'Generate Records',
      'No claims could be generated':
        "No claims could be generated. Most probably information provided is inconsistent or contradicts MSP Fee Schedule. Please click on 'Back' button and review fee codes and service date/times.",
      Claim_History_for: 'Claim History for',
      Submit_Debit_Request: 'Submit Debit Request',
      Code_is_supplementary:
        'Code {0} is not in effect on the date of service or it is supplementary code. It may require a service code, which seems to be missing. Please select a service code, and the supplementary code will be added automatically, if warranted.',
      Code_is_not_eligible_for_this_service: 'Code {0} is not eligible for this service. It was discarded.',
      Clear_start_and_finish_time: 'Clear start and finish time',
      Clear_start_finish_time_and_units: 'Clear start time, finish time and units',
      Clear_start_finish_time_and_units_for: 'Clear start time, finish time and units for {0}, {1}',
      Duplicate_row: 'Duplicate row',
      Clear_dx_codes: 'Clear dx codes',
      Clear_dx_codes_for: 'Clear dx codes for {0}, {1}',
      Clear_referral_codes: 'Clear referral codes',
      Clear_referral_codes_for: 'Clear referral codes for {0}, {1}',
      Clear_comment: 'Clear comment',
      Clear_all_time: 'Clear all times',
      Clear_all_dx_codes: 'Clear all codes',
      Clear_all_referral_codes: 'Clear all codes',
      Clear_all_comments: 'Clear all comments',
      Delete_patient: 'Delete patient',
      Same_Dx: 'Same Dx',
      Same_Referral: 'Same Referral',
      Latest_Dx: 'Latest Dx',
      Latest_Referral: 'Latest Referral',
      Off: 'Off',
      Same_Duration: 'Same Duration',
      Apply_to_all_dx_tooltip: 'Turn it on if you want to use the same Dx code for all patients',
      Apply_to_all_referral_tooltip: 'Turn it on if you want to use the same Referral code for all patients',
      Latest_dx_tooltip: 'Turn it on if you want to use Dx codes claimed on the last visit for this patient',
      Latest_referral_tooltip: 'Turn it on if you want to use Referral codes claimed on the last visit for this patient',
      Apply_to_all_time: 'Switch it on to apply same duration to all patients if the patients are seen consecutively',
      Select_Dx: 'Select Dx',
      Duplicate_Dx: 'Duplicate Dx',
      Duplicate_dx_for_same_patient: 'Duplicate dx for same patient',
      Duplicate_Referral: 'Duplicate Referral',
      Duplicate_referral_for_same_patient: 'Duplicate referral for same patient',
      Select_manualy: 'Select manualy',
      Select_Referral: 'Select Referral',
      Select_Time: 'Select Time',
      Add_Comment: 'Add Comment',
      Preview: 'Preview',
      Are_you_sure_you_want_to_delete_this_record: 'Are you sure you want to delete this record?',
      You_changed_the_order_of_the_patients:
        'You changed the order of the patients. Do you want to check start/end time for each patient before proceeding?',
      The_data_that_you_entered_on_this_screen_will_be_cleared:
        'The data that you entered on this screen will be recalculated. Do you want to proceed?',
      Your_entered_data_might_be_lost_or_changed: 'Your entered data might be lost or changed. Do you want to continue?',
      End_time_is_less_than_start_time: 'End time is less than start time. Allowed for night shifts',
      Did_you_enter_future_dates_patient: "Is it tomorrow's visit? Please enter {0} claims only on this screen. Bill another date separately",
      We_detected_a_case_of_service_that_might_have_happened_on_the_next_day:
        'This billing is for {0}, but it appears that some services happened on the next day. Please bill each date separately.',
      The_Group_Start_and_End_Time_will_override_individual_times:
        'The Batch Start and End Time will override individual times that you entered on Select Time screen.  Do you want to proceed? If not, consider removing group time values.',
      Fac: 'Fac.',
      Previous_claim_from: 'Previous claim from {0} to {1} on {2}',
      ICBC_valid_format_tooltip: 'Valid format:  A1234567 or AA123456.  Accepted characters: A-Z, 0-9. Don’t know the number? Keep it blank.',

      //Debit Request dialog
      Debit_Request: 'Debit Request',
      Enter_the_reason_why_the_claim_was_submitted_in_error:
        'I want to reverse submitted/paid claim #{0} on DOS {1} for {2} in the amount of {3} due to the following reason:',
      If_you_want_to_cancel_in_process_claim_consider_calling_teleplan_support_line:
        'If you want to cancel in-process claim, consider calling Teleplan support line at 604 456-6950, options 3, 2 or 1 866 456-6950, options 3, 2 (toll free) and provide your Teleplan data centre number and the sequence number of the claim. The claim will be cancelled immediately. You will be able to resubmit the correct claim with no delay.',
      If_the_claim_was_paid_use_this_feature_to_reverse_it: 'If the claim was paid, use this feature to reverse it. It is not done by phone.',
      If_you_submit_debit_request_using_this_feature_the_claim_will_be_cancelled_or_reversed:
        'If you submit debit request using this feature, the claim will be cancelled or reversed at the time of the corresponding remittance date. The best time to submit a corrected claim is after your debit request is processed, especially if both claims are on the same date.',
      No_need_to_return_payment_to_MSP_for_a_reversed_claim:
        'No need to return payment to MSP for a reversed claim. Its amount will be deducted from your next remittance report.',

      //ClaimDetails -> Success
      Claim_other_patient: 'Claim other patient(s)',
      Claim_same_patient: 'Claim same patient(s)',
      Go_to_patient_profile: "Open patient's profile",
      Go_to_previous_page: 'Go to previous page',
      You_submitted_record_for: 'You {0} {1} record(s) for {2}',
      You_submitted_record_for_Chiro: 'You submited WorkSafeBC report Fundefined for',
      You_have_created_x_drafts: 'You have created {0} drafts. What do you want to do next?',
      Claim_was_not_submitted: 'Claim was not submitted. Please try again. If problem persists, please contact the system administrator',

      //PrivateDetails -> Success
      You_created_record_for: "You've {0} {1} invoice for {2}",
      Created: 'Created',
      Updated: 'Updated',
      Deleted: 'Deleted',
      Bill_same_patients: 'Bill same patient(s)',
      Bill_other_patients: 'Bill other patient(s)',
      Teleplan_first_visit: 'Teleplan 1st visit',
      First_visit: '1st visit',
      Open_patient: 'Open patient',
      Open_profile: 'Open profile',

      //EditPanel
      Show_names: 'Show names',
      Show_codes: 'Show codes',
      Most_Recent: 'Quick Pick',
      Payor: 'Payor',
      Service_date: 'Service Date',
      Fees_Item: 'Fees Item(s)',
      Search_for_codes: 'Search for codes',
      Find_fee_code_in_the_catalog: 'Find fee code in the catalog',
      Find_dx_in_the_catalog: 'Find Dx in the catalog',
      Find_practitioner_in_the_catalog: 'Find practitioner in the catalog',
      Select_code: 'Select code',
      'Diagnostic codes': 'Diagnostic codes',
      Start_time: 'Start Time',
      Group_Start_Time: 'Batch Start Time',
      This_time_will_apply_to_all_patients_in_the_group: 'This time will apply to all patients in the group',
      End_time: 'End Time',
      Group_End_Time: 'Batch End Time',
      Call_time: 'Call Time',
      Facility: 'Facility',
      Reason_for_01080: 'Reason for 01080',
      Sub_Facility: 'Sub Facility',
      Save_as_Draft: 'Save as Draft',
      Name_PHN_DOB: {
        1: 'Name, PHN, DOB (MM/DD/YYYY) or phone number',
        2: 'Name, PHN, DOB (MM/DD/YYYY)'
      },
      Bill_To: 'Bill To',
      Pay_To: 'Pay To',
      Payee_Address: "Payee's Address",
      Billing_Address: 'Billing Address',
      Terms: 'Terms',
      Due_on_Receipt: 'Due on Receipt',
      Invoice_Date: 'Invoice Date',
      Due_Date: 'Due Date',
      Message_On_Invoice: 'Message on Invoice',
      Net: 'Net',
      Private: 'Private',

      Submission_Code: 'Submission Code',
      Are_you_trying_to_create_a_new_claim_or_modify_an_existing_claim:
        'Are you trying to create a new claim or modify an existing claim? Below is the list of the existing claims. Please select the claim that you want to modify or click the “Create New Claim” button to add new claim for the patient.',
      Created_on: 'created on',
      Update: 'Update',
      Select: 'Select',
      Select_all: 'Select all',
      Comment: 'Comment',
      Category: 'Category',
      //EditPanel -> HeaderInfo
      No_rural_code: 'No rural code',
      Date_created: 'Date created',
      Date_updated: 'Date updated',
      //EditPanel -> MultipleDatePicker
      Bill_multiple_dates: 'Bill multiple dates',

      //ReviewPanel
      'Fee Code': 'Fee code',
      'Diagnostic Code': 'Diagnostic code',
      Fee_amount: 'Fee amount',
      Fee_total: 'Fee total',
      From: 'From',
      Units: 'Units',
      Unit: 'Unit(s)',
      Total: 'Total',
      Amount_received: 'Amount received',
      Balance_due: 'Balance due',
      Subtotal: 'Subtotal',
      Percent: 'Percent',
      Review: 'Review',
      Details: 'Details',
      Add_note: 'Add Note',
      BMI: 'BMI',
      more: 'more',
      Practititoner: 'Practititoner',
      Click_on_the_value_to_edit: 'Click on the value to edit',
      Are_you_sure_delete_this_invoice: 'Are you sure you want to delete this invoice?',
      Are_you_sure_delete_this_record: 'Are you sure you want to delete this record?',
      No_claims_to_display: "No claims to display. Please click 'Back' to create claims or 'Cancel' to exit.",

      //Sales Tax dropdown suggestions
      Exempt: 'Exempt',
      Zero_rated: 'Zero-rated',
      Out_of_Scope: 'Out of Scope',
      GST_PST_BC: 'GST/PST BC',
      PST_BC: 'PST BC',

      //PreviewPanel
      Invalid_records: 'Invalid records',
      Total_claims: 'Total claims',
      Total_amount: 'Total amount',
      Report: 'Report',
      See_report_on_invalid_records: 'See report on invalid records',
      Save_your_claim_without_submitting_it: 'Save your claim without submitting it.',
      Submit_your_claim_to_insurer:
        'Submit your claim(s) to insurer. No changes are allowed to submitted claims, but they can be canceled if submitted in error.',
      No_Rural_Code: 'No Rural Code',

      //INVOICE
      Payee: 'Payee',

      //EmergencyPanel
      'Call Out': 'Call Out',
      OOF_Premiums: 'OOF Premiums',
      Neither: 'Neither',
      CCFPP: 'CCFPP',
      'Immediate travel': 'Immediate Travel',
      Emergency: 'Emergency',
      Out_of_office_hours_premiums: 'Out-of-office hours premiums',

      //PayeePiker
      Claim_Number: 'Claim Number',
      Claim: 'Claim',
      Claim_s: 'Claim(s)',
      Injury_Day: 'Injury Day',
      Nature_of_Injury: {
        1: 'Nature of Injury',
        2: 'NOI',
        3: 'CSA NOI'
      },
      Area_of_Injury: {
        1: 'Area of Injury',
        2: 'AOI',
        3: 'Area of Injury (CSA BP)'
      },
      Anatomic_Code: {
        1: 'Anatomic Code',
        2: 'Anat'
      },
      Anatomic_Position: 'Anatomic Position',
      Injury_Date: {
        1: 'Injury Date',
        2: 'Injury date'
      },
      'Patient Pay': 'Patient Pay',
      N_Not_Applicable: 'N - Not Applicable',
      L_Left: 'L - Left',
      R_Right: 'R - Right',
      B_Both: 'B - Both',
      Left: 'Left',
      Right: 'Right',
      Both: 'Both',
      ASA4: 'ASA 4',
      ASA4E: 'ASA 4E',
      ASA5: 'ASA 5',
      ASA5E: 'ASA 5E',
      IABP: 'IABP',
      Mechanical_assist_device: 'Mechanical assist device',
      Time_over_8_hours: 'Time over 8 hours',

      //HeaderPanel
      Therapist: 'Therapist',
      Role: 'Role',
      Location: 'Location',
      Referral: 'Referral',
      'Referring Practitioner': 'Referring Practitioner',
      Ref_Practitioner: 'Ref. Practitioner',
      Referred_By: 'Referred By',
      Rural_code: 'Rural Code',
      Location_code: 'Location Code',

      //ReferralRadio
      N_A: 'N/A',
      By: 'By',
      To: 'To',

      //ClaimView
      Counters: 'Counters',
      'Filter records': 'Filter records',
      Delete_Claim: 'Delete Claim',
      Delete_Referral: 'Delete Referral',
      Archive_Claim: 'Archive Claim',
      Archive_eClaim: 'Archive eClaim',
      Are_you_sure_you_want_to_delete_invoice: 'Are you sure you want to delete invoice #{0} for patient {1}?',
      Are_you_sure_you_want_to_archive_invoice: 'Are you sure you want to archive invoice #{0} for patient {1}?',
      No_service_provider: 'No service provider',

      //Support page
      Claim_manager_support_and_sales: 'Claim Manager support and sales',
      Teleplan_Support: 'Teleplan Support',
      Contact_claim_manager_for_all_your_medical_billing_needs: 'Contact Claim Manager for all your medical billing needs',
      Contact_claim_manager_for_all_your_medical_billing_needs_and_teleplan_enrollment:
        'Contact Teleplan support for management of declined claims and Teleplan enrollment',
      Working_hours: 'Working Hours',
      Claim_manager_online_24_7: 'Claim Manager Online: 24/7',
      Support_and_inquiries: 'Support and inquiries: (hrs) M-Sun, 9:00 am to 23:00 pm',
      We_take_calls_on_evenings_and_weekends: 'We take calls on evenings and weekends',
      Teleplan_enrollment_and_cancelling_in_process_claims: 'Teleplan enrollment and cancelling in-process claims',
      Billing_support_and_troubleshooting_of_declined_claims: 'Billing support and troubleshooting of declined claims',
      toll_free: 'toll free',
      Submit_Teleplan_related_forms: 'Submit Teleplan related forms',
      Submit_Request_for_Approval_of_Over_Age_claims: 'Fax Request for Approval of Over-Age claims',
      Check_Patients_coverage: "Check Patient's coverage 24/7",
      in_Victoria: 'in Victoria',
      in_Vancouver: 'in Vancouver',
      all_other_areas_of_BC: 'all other areas of BC, toll-free',
      '24_hours_automated_line': '24-hours automated line',

      //Inputs error message
      'This field is required': 'This field is required',

      //LockButton
      'Locked icon remembers selected value': 'Locked icon remembers selected value.',

      //ClaimSearch
      'Find claim': {
        desktop: 'Find record by its number, Name, PHN or DOB (MM/DD/YYYY)',
        mobile: 'Record #, Name, PHN or DOB (MM/DD/YYYY)'
      },

      //LegoDetailsView
      No_data_found: 'No data found',

      //LegoView
      Default_sort_order: {
        1: 'Default sort order: recently updated/created first',
        2: 'Default sort order: Last Name'
      },
      A_R: 'A/R',
      //column titles
      DOS: 'DOS',
      Patient: {
        1: 'Patient',
        2: 'Patient(s)',
        3: 'Patients'
      },
      Type: 'Type',
      Payer: 'Payer',
      Dx: 'Dx',
      ICD9: 'ICD9',
      Code: 'Code',
      Fee: 'Fee',
      Billed: 'Billed',
      Due: 'Due',
      Status: 'Status',
      'Record Id': 'Record Id',
      Record_Id_Tooltip: "Record ID, (o) - claim's office number (internal), (s)  - sequence number (Teleplan number).",
      Acupuncture: 'Acupuncture',
      Duration: 'Duration',
      Common_notes: 'Common notes',
      Recent_codes: 'Recent codes',
      Discount: {
        1: 'Discount',
        2: 'DISC'
      },
      Sales_Tax: 'Sales Tax',
      GST: 'GST',
      PST: 'PST',
      In_Store_Credit: 'In-store Credit',
      Balance_Due: 'Balance Due',
      Service_Product: {
        1: 'Serv/Prod',
        2: 'Service/Product',
        3: 'Services/Products'
      },
      Rate: 'Rate',
      Provider: 'Provider',
      Tax: 'Tax',
      Quantity: {
        1: 'Qty'
      },

      //rowTemplate
      'Billed by': 'Billed by',
      'Paid to': 'Paid to',

      //InputWithClear
      Clear: 'Clear',

      //ClaimFilters
      Practitioner: 'Practitioner',
      'Payment assignment': 'Payment assignment',
      Sent: 'Sent',
      'Submission Date': {
        1: 'Submission date',
        2: 'Sub. Date'
      },
      'Remittance Date': 'Remittance date',
      'End Date is earlier than the Start Date': 'End Date is earlier than the Start Date.',
      Swap: 'Swap',

      //LegoTable
      No_records_found: 'No records found',

      //Chip labels
      Period: 'DOS',
      SubmissionDate: 'Sent',
      SCCode: 'Rural',
      Rural: 'Rural',
      ICD9Code: 'Dx',
      FeeCode: 'Code',
      FullName: 'Patient',
      PatientGuid: 'Patient',
      RecordType: '',
      InvoiceType: '',
      StatusText: '',
      DoctorGuid: '',

      //RemoveFiltersForMoreRecords
      Remove_filters_to_see_more_records: 'Remove filters to see {0} more records',

      //Paginator right
      Show_Archived: 'Show Archived',
      Expand_all: 'Expand all',
      Collapse_all: 'Collapse all',

      //Mobile row expantion
      More: 'More',
      Less: 'Less',

      //ViewPanel
      Claims: 'Claims',
      No_claims_found: 'No claims found',

      //CodePicker
      Warning: 'Warning',
      Invalid_code: 'Invalid code',

      //ReviewPanelActionButtons
      You_made_some_changes_to_this_screen:
        'You made some changes to this screen. If you go back, these changes will disappear. Do you want to continue?',

      //claims -> helpers -> toastMessages
      //emergencyHelperText toast
      'Out-Of-Office_Hours_Premiums': 'Out-Of-Office Hours Premiums',
      YES_premiums: `YES might qualify the service for out-of-office premiums. Select "yes", if all is true:`,
      the_physician_is_specially_called: 'the physician is specially called',
      the_service_is_emergency: 'the service(s) is emergency or non-elective.',
      'The_applicable_fee_for_out-of-office_hours':
        'The applicable fee for out-of-office hours will be added and calculated automatically if the service meets the criteria. You can add the fee item manually if you want to overwrite the algorithm.',
      Select_this_button_if_all_is_true: 'Select this button if all is true:',
      The_MD_is_specially_called_to_see_the_patient: 'The MD is specially called to see the patient.',
      The_service_is_emergency_or_non_elective: 'The service(s) is emergency or non-elective.',
      We_will: 'We will',
      Add_continuing_care_fee_items_and_calculate_the_units_when_applicable: 'add continuing care fee items and calculate the units when applicable.',
      If_MD_was_called_to_leave_immediately:
        'If MD (a) was called to leave immediately and traveled to another location or (b) attended this patient without a break from a previous patient, click on Call-Out or CCFPP button respectively. The service may be eligible for a call-out fee code and/or additional continuing care units.',
      Do_not_select_any_OOFHP_codes_manually:
        'Do not select any OOFHP codes manually. They will be added automatically to the main service code if the service qualifies. Only add OOFHP codes manually if you want to overwrite the algorithm.',

      //immdiateTravelHelperText toast
      'Call-Out_Charges': 'Call-Out Charges',
      Missing_information: 'Missing information',
      YES_charges: `YES might qualify the service for call-out charges. Select "yes", if all is true:`,
      had_to_travel_to_see_the_patient: 'had to travel to see the patient',
      had_to_leave_immediately: 'had to leave immediately',
      first_patient_examined: 'first patient examined and treated at any special visit.',
      'The_out-of-office_hours_premium_listings':
        "The out-of-office hours premium listings apply only to those servicesinitiated and rendered within the designated time limits. They apply to visits to a physician's office only if theoffice is officially closed during the designated time period.",
      This_services_qualifies_for_OOFHP: 'This services qualifies for OOFHP (Out-of-Office Hours Premiums or Emergency).',
      The_MD_had_to_travel_to_see_the_patient: 'The MD had to travel to see the patient.',
      The_MD_had_to_leave_immediately: 'The MD had to leave immediately.',
      It_was_the_first_patient_examined_and_treated_at_any_special_visit: 'It was the first patient examined and treated at any special visit.',
      Add_a_call_out_fee_code_if_applicable: 'add a call-out fee code if applicable.',
      Call_Out_is_applicable_only_if_OOFHP_is_applicable: 'Call Out is applicable only if OOFHP is applicable.',

      //CCFPPHelperText toast
      Continuing_Care_Surcharges: 'Continuing Care Surcharges',
      YES_indicates: `YES indicates that the service is provided to immediately subsequent patient seen on the same call-out as emergency. Select "yes", to`,
      add_CCFPP_note_to_the_claim: 'add CCFPP note to the claim.',
      calculate_the_timing: 'calculate the timing without the 30 minutes delay from the beginning of the visit or consultation.',
      Select_No_to_stat: 'Select No to stat timing after the 30 minutes.',
      This_service_qualifies_for_OOFHP: 'This service qualifies for OOFHP (Out-of-Office Hours Premiums or Emergency).',
      The_service_is_provided_to_immediately_subsequent_patient:
        'The service is provided to immediately subsequent patient seen on the same call-out as emergency.',
      calculate_the_timing_units_without_the_delay:
        'calculate the timing/units without the 30-minute delay from the beginning of the visit or consultation.',
      When_CCFPP_note_is_missing_Teleplan_deducts_one_unit:
        'When CCFPP note is missing, Teleplan deducts one unit from the number of submitted units.',
      CCFPP_is_applicable_only_if_OOFHP_is_applicable: 'CCFPP is applicable only if OOFHP is applicable.',

      //feeItemsHelperText toast
      Fee_Items_Codes: 'Fee Items Codes',
      Select_procedures_and_services:
        'Select procedures and services provided concurrently within the same time period. Call-out and continuing care charges will be added automatically.',
      // patientHelperText toast
      Patient_field_info: 'Patient(s) field info',
      Select_multiple_patients: 'Select multiple patients to create identical claims for every patient in the group.',
      //completeMandatoryFields toast
      Please_complete_the_mandatory_fields: 'Please complete the mandatory fields',
      //editableDropdownWarn toast
      Your_value_is_not_present_in_the_catalog: 'Your value is not present in the catalog. Please select the item from the drop-down list.',
      //maxEntriesOfCodes toast
      Maximum_number_of_entries_is_X: 'You cannot add another item to the list. Maximum number of entries is',
      //showCodeValid4LocationWarn toast
      feeCodes_are_not_billable: 'fee code(s) are not billable with the selected location code. Please change either code.',
      Location_code_P_is_not_suitable_for_selected_fee_items: 'Location code P is not suitable for selected fee items.',
      Fee_code_36045_requires_location_code_R: 'Fee code 36045 requires location code R.',
      Location_code_is_not_compatible_with_fee_code: 'Location code {0} is not compatible with fee code(s) {1}.',
      Service_must_be_performed_in_hospital: 'Service must be performed in hospital',
      //showFeeCodeConflictsWarn toast
      Selected_cannot_be_billed: 'Selected fee codes cannot be billed concurrently.',
      //checkOffHoursWarnToast toast
      Start_time_does_not_correspond: {
        evening:
          'The service start time does not correspond with the fee item code(s) {0}. Weekday evening visit: 6:00 PM to 11:00 PM. Please change the fee item code or the start time.',
        night:
          'The service start time does not correspond with the fee item code(s) {0}. Weekday night visit: 11:00 PM to 08:00 AM. Please change the fee item code or the start time.',
        weekendWholeDay: '{0} - applies only on Sat/Sun/Stat.',
        weekend:
          'The service start time does not correspond with the fee item code(s) {0}. Weekend/Holiday daytime visit: 08:00 AM to 11:00 PM. Please change the fee item code or the start time.',
        workDay:
          'The service start time does not correspond with the fee item code(s) {0}. Weekday daytime visit: 08:00 AM to 06:00 PM. Please change the fee item code or the start time.',
        eveningAndWeekendMsg:
          'The service start time does not correspond with the fee item code(s) {0}. Weekday evening visit: 6:00 PM to 11:00 PM. Weekend/Holiday daytime visit: 08:00 AM to 11:00 PM. Please change the fee item code or the start time.',
        wholeWorkday: '{0} - Applies only on weekdays, excluding statutory holidays.',
        nightMidwife:
          'The service start time does not correspond with the fee item code(s) {0}. Nigh visit: 06:00 PM to 08:00 AM. Please change the fee item code or the start time.',
        dayMidwife:
          'The service start time does not correspond with the fee item code(s) {0}. Daytime visit: 08:00 AM to 06:00 PM. Please change the fee item code or the start time.'
      },

      Please_select_only_one_psychiatry_code: 'Please select only one code {0}. Bill other codes from this group separately',
      The_duration_does_not_match_the_fee_code:
        'The duration does not match the fee code {0}. The matching code is {1}. Fee code {2} must be {3}. Fee code {4} must be {5}.',

      To_demonstrate_that_the_second_claim_with_the_same_fee_code_is_eligible:
        'To demonstrate that the second claim with the same fee code is eligible, please enter the start and end time for both claims and add a note that explains the necessity for the 2nd services to the same patient on the same date. We will add submission code D to the second claim as required by Teleplan specifications.',

      feeCodesForWSBCMessage: 'Codes {0} should be billed to WSBC. Please change the insurer to WSBC.',

      //Prefs toast message
      Preferences_saved_successfully: 'Preferences saved successfully',

      //Patient details toast messages
      Patient_created_successfully: 'Patient created successfully',
      //deleteClaimSuccess toast
      Patient_deleted_successfully: 'The patient is deleted',

      //Appointments toast messages
      //deleteAppointmentSuccessMessage
      Appointment_deleted_successfully: 'Appointment deleted successfully',

      //showSavePrefsSuccess toast
      You_have_updated_your_preferences: 'You have updated your preferences.',
      //showSavePrefsError toast
      The_changes_are_not_saved: 'The changes are not saved.',
      //submitNewClaimSuccess toast
      Your_changes_are_submitted: 'Claim # {0} for patient {1} is submitted. Billed amount is {2}.',
      Claim_is_submitted: 'Claim # {0} for patient {1} is submitted',
      //saveNewClaimSuccess toast
      Your_changes_are_saved: 'Your changes are saved.',
      //archiveClaimSuccessMessage toast
      Claim_has_been_archived: 'Claim has been archived.',
      //deletePatientSuccess toast
      Claim_deleted_successfully: 'Claim deleted successfully',
      //Edit Archived, Declined, Not Paid claims
      You_have_created_a_new_outstanding_claim_by_making_changes_to_a_declined_claim:
        'You have created a new outstanding claim by making changes to a declined claim. The original claim will be archived.',
      Updated_record_is_hidden_becuase_of_the_filters:
        'The updated record is hidden because of the filters that you set on this screen. Please remove the filters, if you want to see the record.',

      You_already_have_a_claim_with_the_same_fee_code_on_the_same_date_of_service:
        'You already have a claim with the same fee code on the same date of service. If this is a valid claim, please select submission code D and enter the start and end time for both claims. You may also want to add a note explaining why the same service was completed two times in the same day. Office number(s)',

      //Global patients search
      Enter_name_PHN_phone_or_other_patient_information_here: 'Last Name, First Name or YYYY/MM/DD or last 7 digits of phone number',
      Enter_office_number_or_sequence_number_of_the_claim_here: 'Enter office number or sequence number of the claim here',

      //QuickSettings
      Change_default_value: 'Click to change the default value.',

      //customPrompt
      You_have_unsaved_changes: 'You have unsaved changes. Are you sure you want to leave?',

      //Demo
      'Demo item deleted successfully': 'Demo item deleted successfully'
    }
  },
  ru: {
    translation: {
      Dashboard: 'Начало',
      Schedule: 'Расписание',
      Patients: 'Пациенты',
      Claims: 'Счета',
      Reports: 'Отчеты'
    }
  }
};

// initialize i18next with catalog and language to use
i18n
  .use(i18nextXHRBackend)
  //.use(i18nextBrowserLanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
    debug: true
  });

const t = i18n.t.bind(i18n);
export { i18n, t };
// export default i18n;
