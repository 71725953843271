import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SignIn from './SignIn/SignIn';
import SignUp from './SignUp/SignUp';
import Bureau from './Bureau/Bureau';
import Logout from './Logout/Logout';
import Redirect from './Redirect/Redirect';
import PasswordUpdate from './PasswordUpdate/PasswordUpdate';
import SignInWithPin from './SignInWithPin';
import BackdoorSignIn from './BackdoorSignIn/BackdoorSignIn';
import Verification from './Verification/Verification';

import { setLoginView } from '../actions/auth.actions.creators';
import { shouldUsePin } from '../../utils/pinCode';
import qs from 'qs';

import './Auth.scss';

const Auth = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const query_str = window.location.search ? window.location.search.replace('?', '') : '';
  const { code } = qs.parse(query_str);
  const sso = query_str.indexOf('key') > -1 && query_str.indexOf('DCNGuid') > -1;

  useEffect(() => {
    if (shouldUsePin()) {
      dispatch(setLoginView('loginWithPin'));
    }
  }, []);

  return (
    <>
      {user.loginView === 'login' && code && <BackdoorSignIn />}
      {user.loginView === 'loginWithPin' && !sso && !code && <SignInWithPin />}
      {user.loginView === 'login' && !sso && !code && <SignIn />}
      {user.loginView === 'register' && <SignUp />}
      {user.loginView === 'verify' && <Verification />}
      {user.loginView === 'updatePassword' && <PasswordUpdate />}
      {user.loginView === 'bureau' && <Bureau />}
      {user.loginView === 'logout' && <Logout />}
      {(user.loginView === 'redirect' || (user.loginView === 'login' && sso)) && <Redirect />}
    </>
  );
};

export default Auth;
