import axios from 'axios';
import { CONTENT_TYPES, CONTENTFUL_ACCESS_TOKEN, CONTENTFUL_ENTRIES_BASE_URL } from './config';

export const getInstructionsWithAssets = async () => {
  try {
    const response = await axios.get(`${CONTENTFUL_ENTRIES_BASE_URL}${CONTENT_TYPES.instuctions}`, {
      headers: {
        Authorization: `Bearer ${CONTENTFUL_ACCESS_TOKEN}`
      }
    });

    const { items, includes } = response.data;

    // Map assets by ID for quick lookup
    const assetsById = (includes?.Asset || []).reduce((acc, asset) => {
      acc[asset.sys.id] = asset;
      return acc;
    }, {});

    return { items, assetsById };
  } catch (error) {
    console.error('Error fetching instructions with assets:', error);
    throw error;
  }
};
