import { t } from '../../../../../service/localization/i18n';
import { commonInputs } from '../../../../config/commonInputsConfig';
import { billToMaxEntry } from '../../../../config/defaultValuesConfig';

export const inputs = () => {
  return {
    mostRecent: commonInputs.mostRecent,

    privateService: {
      id: 'privateServices_items',
      name: 'ServiceGuid',
      descriptionName: 'ServicesList',
      label: t('Service_Product.3'),
      codeType: 'privateServices',
      maxEntries: 999,
      searchAllType: 'service_all'
    },

    payTo: {
      name: 'PayTo',
      label: t('Pay_To'),
      codeType: 'payTo'
    },

    payToAddress: {
      name: 'PayeeAddress',
      label: t('Payee'),
      codeType: 'payToAddress'
    },

    billTo: {
      name: 'BillTo',
      label: t('Payer'),
      codeType: 'billTo',
      maxEntries: billToMaxEntry
    },

    billToAddress: {
      name: 'BillToAddressLine',
      label: t('Payer'),
      codeType: 'billToAddress'
    },

    terms: {
      name: 'Terms',
      label: t('Terms'),
      codeType: 'terms'
    },

    invoiceDate: {
      name: 'InvoiceDate',
      label: t('Invoice_Date'),
      codeType: 'invoiceDate'
    },

    dueDate: {
      name: 'DueDate',
      label: t('Due_Date'),
      codeType: 'dueDate'
    },

    invoiceNumber: {
      name: 'InvoiceNumber',
      label: `${t('Invoices.3')} #`,
      codeType: 'invoiceNumber'
    },

    messageOnInvoice: {
      name: 'Comment',
      label: t('Message_On_Invoice'),
      codeType: 'messageOnInvoice'
    }
  };
};
