import React, { useState, useEffect } from 'react';
import {useSelector} from 'react-redux';
import { FormProvider } from 'react-hook-form';
import { Button } from 'primereact/button';

import UsernameStep from './Steps/UsernameStep';
import PasswordStep from './Steps/PasswordStep';
import AuthFormWrap from '../../../../components/Wraps/PageWrap/AuthFormWrap';

import { useSingIn } from './hooks/useSignIn';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const SignIn = () => {
  const history = useHistory();
  const methods = useSingIn();
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const { isMobile } = useSelector((state) => state.core.window);

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const [isStandalone, setIsStandalone] = useState(false);





  useEffect(() => {
    // Redirect to home
    history.replace('/');

    const checkStandaloneMode = () => {
      const standalone =
        window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
      setIsStandalone(standalone);
    };

    checkStandaloneMode();

    // Listen for the 'beforeinstallprompt' event
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e); // Save the event
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  }, [history]);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      console.log(outcome === 'accepted' ? 'App installed' : 'App installation dismissed');
      setDeferredPrompt(null); // Clear the deferred prompt after use
    }
  };

  return (
    <AuthFormWrap label="Welcome to Claim Manager">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(methods.onSubmit)} name="SignIn">
          <div className="flex flex-column gap-3">
            {methods.signInState.step === 1 && <UsernameStep />}
            {methods.signInState.step === 2 && <PasswordStep />}
            {/* Show the install button only for non-iOS mobile devices */}
            {}
            {!isStandalone && isMobile && !isIOS && deferredPrompt && (
              <Button
                type="button"
                onClick={handleInstallClick}
                className="install-button p-button-outlined"
                style={{ width: '100%', justifyContent: 'center' }}
              >
                Install App
              </Button>
            )}

            {/* Show a custom message for iOS users */}
            {!isStandalone && isMobile && isIOS && (
              <div className="ios-install-prompt">
                <p>To install this app on your iPhone, tap <strong>Share</strong> and then <strong>Add to Home Screen</strong>.</p>
              </div>
            )}
          </div>
        </form>
      </FormProvider>
    </AuthFormWrap>
  );
};

export default SignIn;
