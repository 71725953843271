import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { routes } from '../../../../routes/routes';
import { instructionIds } from '../../../instructions/helpers/instructionIds';

const LabelsAlert = () => {
  const history = useHistory();
  const { isMobile } = useSelector((state) => state.core.window);

  // if (!isMobile) {
  //   return null;
  // }

  return (
    <Message
      severity="info"
      className="mb-2"
      content={
        <div className="flex align-items-center gap-2 text-lg">
          You can snap labels now.
          <Button
            label="Learn more"
            className="p-0 text-blue-700 underline"
            link
            onClick={() => history.push(`${routes.instructions.path}/${instructionIds.labels}`)}
          />
        </div>
      }
    />
  );
};

export default LabelsAlert;
