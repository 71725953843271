import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { Button } from 'primereact/button';
import PageWrap from '../../../../components/Wraps/PageWrap/PageWrap';
import PinCode from '../../../../components/Widgets/PinCode';
import MobileFeatureAlert from './MobileFeatureAlert';

import { pinNotMatchToast } from './pinNotMatchToast';
import { editPinCode } from '../../actions/user.action.creators';
import { localStorageKeys } from '../../../config/localStorageKeysConfig';
import { hashPinCode, showPin } from '../../../utils/pinCode';
import { setToastMessage } from '../../../core/actions/core.action.creators';
import { signInWithPinCode } from '../../../auth/actions/auth.actions.creators';
import { routes } from '../../../../routes/routes';
import { t } from '../../../../service/localization/i18n';

const EditPinCode = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.details);
  const storedPinData = JSON.parse(localStorage.getItem(localStorageKeys.pinCode));
  const title = t('Edit_pin_code');

  const [state, setState] = useState({
    pin: '',
    step: 1
  });

  const handleStepOneNext = async (data) => {
    // Retrieve the stored hashed pin code from localStorage
    const storedPinData = JSON.parse(localStorage.getItem(localStorageKeys.pinCode));

    if (!storedPinData || !storedPinData.DeviceGuid) {
      return dispatch(
        setToastMessage({
          message: 'No PIN-code is set for this device. Please set up a PIN-code in your settings before proceeding.',
          type: 'error',
          lifeTime: 10000
        })
      );
    }

    // Hash the entered pin code for comparison
    const enteredPinHash = await hashPinCode(data.pin);

    // Check if the hashed entered pin matches the stored pin hash
    const isPinValid = await signInWithPinCode({
      DCNGuid: storedPinData.DCNGuid,
      DeviceGuid: storedPinData.DeviceGuid,
      Fingerprint: enteredPinHash,
      Signature: storedPinData.Signature
    });

    if (isPinValid) {
      // Update the state with the entered pin and proceed to the next step
      setState((prevState) => ({ ...prevState, step: 2 }));
    } else {
      // Show an error message if the pin does not match
      dispatch(
        setToastMessage({
          message: 'Incorrect PIN. Please try again.',
          type: 'error',
          lifeTime: 10000
        })
      );
    }
  };

  const handleStepTwoeNext = (data) => {
    setState((prevState) => ({ ...prevState, pin: data.pin, step: 3 }));
  };

  const onSave = async (data) => {
    // Verify if the entered pin code matches the new pin
    if (state.pin !== data.pin) {
      return pinNotMatchToast();
    }

    if (!storedPinData || !storedPinData.DeviceGuid) {
      throw new Error('No stored device information found. Please try again.');
    }

    const hashedPin = await hashPinCode(data.pin);
    const deviceGuid = storedPinData.DeviceGuid; // Use the same DeviceGuid!

    // Attempt to edit the pin code by making an API call
    const response = await editPinCode({
      DoctorGuid: user.UserGuid,
      DCNGuid: user.DCNGuid,
      DeviceGuid: deviceGuid,
      DeviceName: '',
      Fingerprint: hashedPin,
      Signature: storedPinData.Signature
    });

    if (response) {
      // If API call is successful, store "usePin" as `true` in local storage
      localStorage.setItem(
        localStorageKeys.pinCode,
        JSON.stringify({ usePin: true, DeviceGuid: deviceGuid, DCNGuid: user.DCNGuid, Signature: response.Signature })
      );
      dispatch(setToastMessage({ message: 'Your PIN is updated! Use it to log in securely.', type: 'success', lifeTime: 7000 }));
      history.push('/');
    } else {
      dispatch(setToastMessage({ message: 'An error occurred while setting your PIN. Please try again.', type: 'error', lifeTime: 10000 }));
    }
  };

  const onBack = (step) => {
    setState((prevState) => ({ ...prevState, step }));
  };

  const onCancel = () => {
    history.push('/');
  };

  if (!showPin()) {
    return <MobileFeatureAlert />;
  }

  if (!storedPinData) {
    return (
      <div className="flex justify-content-start align-items-center flex-column pt-6 px-3 w-full">
        <span className="text-lg text-center">No PIN-code is set for this device. Please set up a PIN-code in your settings before proceeding.</span>
        <div className="flex gap-2 mt-4">
          <Button outlined label="Return to Home" onClick={() => history.replace('/')} />
          <Button label="Create PIN code" onClick={() => history.replace(routes.createPin.path)} />
        </div>
      </div>
    );
  }

  return (
    <PageWrap>
      <div className="flex gap-3">
        {state.step === 1 && (
          <PinCode title={title} subtitle="Enter current PIN" onCancel={onCancel} submitButtonLabel="Next" onSubmit={handleStepOneNext} />
        )}
        {state.step === 2 && (
          <PinCode
            title={title}
            subtitle="Enter new PIN"
            onBack={() => onBack(1)}
            submitButtonLabel="Next"
            onCancel={onCancel}
            onSubmit={handleStepTwoeNext}
          />
        )}
        {state.step === 3 && <PinCode title={title} subtitle="Confirm new PIN" onBack={() => onBack(2)} onCancel={onCancel} onSubmit={onSave} />}
      </div>
    </PageWrap>
  );
};

export default EditPinCode;
