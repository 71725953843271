import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { setAppMode, setToastMessage } from '../../../../core/actions/core.action.creators';
import { devSignIn, getUserProfileData, setLoginView, signIn, updateUser } from '../../../actions/auth.actions.creators';
import { localStorageKeys } from '../../../../config/localStorageKeysConfig';
import { routes } from '../../../../../routes/routes';
import { showPin } from '../../../../utils/pinCode';
import moment from 'moment';

export const useSubmit = ({ signInState, setSignInState }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    const bureauUser = sessionStorage.getItem('bau');
    if (bureauUser) {
      sessionStorage.removeItem('bureau');
      sessionStorage.removeItem('bau');
    }

    // handleSignIn(data);

    if (process.env.REACT_APP_CYPRESS) {
      if (data.username === 'demo') {
        // Use "demo" account for all cypress tests
        developmentSignIn(data);
      } else {
        // If not "demo". Need for log in cypress tests
        handleSignIn(data);
      }
    } else {
      // Normal sign in
      handleSignIn(data);
    }
  };

  const handleSignIn = async (data) => {
    // Save username to local storage
    // WIN-599 - remember login name
    if (data.signed) localStorage.setItem(localStorageKeys.username, data.username);

    const dataForSubmit = {
      username: signInState.step === 2 ? signInState.impersonateKey : data.username,
      password: data.password,
      step: signInState.step
    };

    // Show loading
    setSignInState((prevState) => ({ ...prevState, isSubmitting: true }));

    const result = await signIn(dataForSubmit);

    const jwtToken = result?.JwtToken;
    if (jwtToken) {
      const tabId = sessionStorage.getItem('TAB_ID_KEY');
      const tabToken = `${tabId}_token`;
      sessionStorage.setItem(tabToken, jwtToken);
    }

    // Hide loading
    setSignInState((prevState) => ({ ...prevState, isSubmitting: false }));
    const validStatuses = [0, 1, 2];
    if (result) {
      const lastAttempt = moment(result.LastLogin);
      const nextAttempt = lastAttempt.add(30, 'minutes');
      const formattedNextAttempt = nextAttempt.format('MMMM Do YYYY, h:mm:ss a');
      const message = `Your account is locked until ${formattedNextAttempt}`;

      // If password is expired then redirect to update password page
      if (result.UserStatus === 426) {
        dispatch(setLoginView('updatePassword'));
        dispatch(updateUser(result));
        return;
      }

      if (!validStatuses.includes(result.UserStatus)) {
        dispatch(setToastMessage({ type: 'error', message }));
        return;
      }

      // User locked out due to several invalid login attempts
      if (result.UserStatus === 429) {
        dispatch(setToastMessage({ type: 'error', message }));
        return setSignInState((prevState) => ({
          ...prevState,
          step: 1,
          impersonateKey: null
        }));
      }

      // Step 1 - username
      if (signInState.step === 1) {
        return setSignInState((prevState) => ({
          ...prevState,
          step: 2,
          impersonateKey: result.ImpersonateKey
        }));
      }

      // Step 2 - password
      if (signInState.step === 2) {
        if (result.UserStatus === 429) {
          // User locked out due to several invalid login attempts
          dispatch(setToastMessage({ type: 'error', message }));
          return setSignInState((prevState) => ({
            ...prevState,
            step: 1,
            impersonateKey: null
          }));
        } else {
          // Set user data to redux for sending correct Authorization headers for `getUserProfileData` batch requests
          dispatch(updateUser(result));

          const demoAccounts = ['march', 'demo', 'midwife'];
          const appMode = demoAccounts.includes(data.username) ? 'demo' : 'live';

          dispatch(setAppMode(appMode, true));

          if (result.Role === 'bureau') {
            dispatch(setLoginView('verify'));
          } else {
            // Show loading
            setSignInState((prevState) => ({ ...prevState, isSubmitting: true }));
            await getUserProfileData(result);
            // Hide loading
            setSignInState((prevState) => ({ ...prevState, isSubmitting: false }));

            const pinCode = JSON.parse(localStorage.getItem(localStorageKeys.pinCode));
            // If pin code is not set and it's mobile device then navigate to create pin code page else navigate to dashboard
            const navigateTo = pinCode === null && showPin() ? routes.createPin.path : '/';
            history.replace(navigateTo);
          }
        }
      }
    }
  };

  const developmentSignIn = async (data) => {
    if (signInState.step === 1) {
      try {
        const dataForSubmit = { username: 'demo', password: '' };
        // Show loading
        setSignInState((prevState) => ({ ...prevState, isSubmitting: true }));
        await devSignIn(dataForSubmit);
        // Show loading
        setSignInState((prevState) => ({ ...prevState, isSubmitting: false }));
        // Navigate to dashboard
        history.replace('/');
      } catch (error) {
        console.log('!!!developmentSignIn', error);
      }
    }

    if (signInState.step === 2) {
      if (data.password) {
        handleSignIn(data);
      } else {
        try {
          const dataWithDefaultPassword = { username: signInState.impersonateKey, password: 'vba777A!' };
          // Show loading
          setSignInState((prevState) => ({ ...prevState, isSubmitting: true }));
          await devSignIn(dataWithDefaultPassword);
          // Show loading
          setSignInState((prevState) => ({ ...prevState, isSubmitting: false }));
          await handleSignIn(dataWithDefaultPassword);
        } catch (error) {
          console.log('!!!developmentSignIn', error);
        }
      }
    }
  };

  // const developmentSignIn = async (data) => {
  //   if (signInState.step === 1) {
  //     if (data.username) {
  //       handleSignIn(data);
  //     } else {
  //       try {
  //         const dataForSubmit = { username: 'demo', password: '' };
  //         // Show loading
  //         setSignInState((prevState) => ({ ...prevState, isSubmitting: true }));
  //         await devSignIn(dataForSubmit);
  //         // Show loading
  //         setSignInState((prevState) => ({ ...prevState, isSubmitting: false }));
  //         // Navigate to dashboard
  //         history.replace('/');
  //       } catch (error) {
  //         console.log('!!!developmentSignIn', error);
  //       }
  //     }
  //   }

  //   if (signInState.step === 2) {
  //     if (data.password) {
  //       handleSignIn(data);
  //     } else {
  //       try {
  //         const dataWithDefaultPassword = { username: signInState.impersonateKey, password: 'vba777A!' };
  //         // Show loading
  //         setSignInState((prevState) => ({ ...prevState, isSubmitting: true }));
  //         await devSignIn(dataWithDefaultPassword);
  //         // Show loading
  //         setSignInState((prevState) => ({ ...prevState, isSubmitting: false }));
  //         await handleSignIn(dataWithDefaultPassword);
  //       } catch (error) {
  //         console.log('!!!developmentSignIn', error);
  //       }
  //     }
  //   }
  // };

  return { onSubmit };
};
