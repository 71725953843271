import React, { useEffect, useState } from 'react';

import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { AutoComplete } from 'primereact/autocomplete';

import InputWrapper from '../InputWrapper/InputWrapper';
import HighlightSearchQuery from '../../Misc/HighlightSearchQuery/HighlightSearchQuery';
import PatientFormDialog from '../../Widgets/PatientFormDialog';

import { elementIDs } from '../../../modules/config/elementIDsConfig';
import { dropdownScrollHeight } from '../../../modules/config/stylesConfig';
import { getPatients } from '../../../service/Lookup';
import { formatPatientInfoForClaims } from '../../../modules/patients/helpers/formatPatientInfoForClaims';
import { patientFullNameWithNicknameAndAge, patientFullNameWithAge } from '../../../modules/utils/patientFullName';
import { formatDate } from '../../../modules/utils/formatDate';
import { t } from '../../../service/localization/i18n';
import cx from 'classnames';

import './Patient.scss';

// interface IPatients {
//   value: any,
//   name: string,
//   onChange: () => void,

//   label?: string,
//   hideLabel?: string,
//   errorMessage?: string,
//   placeholder?: string,
//   newPatientButtonTooltip?: string,
//   maxEntry?: number,
//   searchForRepresentative?: boolean,
//   isInvalid?: boolean,
//   required?: boolean,
//   multiple?: boolean,
//   autoFocus?: boolean,
//   disabled?: boolean,
//   forceSelection?: boolean,
//   showAddPatientButton?: boolean,
//   onBlur?: () => void,
//   onAdd?: () => void,
//   footerContent?: () => JSX.Element | JSX.Element
//   inputWrapStyles?: React.CSSProperties
// };

const Patient = React.forwardRef(({ showAddPatientButton = true, ...props }, ref) => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showNewPatientDialog, setShowNewPatientDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // className for cypress tests
  const classNameForTests = cx('block', props.isInvalid ? 'p-invalid' : '');
  const classNameForLive = cx('block', props.isInvalid ? 'p-invalid' : '', {
    patient_picker_autocomplete_single_code: props.value && props.maxEntry === props.value?.length
  });
  const className = process.env.REACT_APP_CYPRESS ? classNameForTests : classNameForLive;

  useEffect(() => {
    const initErrorMessage = props.errorMessage || '';
    setErrorMessage(initErrorMessage);
  }, [props.value, props.errorMessage]);

  const fetchPatients = (query) => {
    setQuery(query);
    getPatients({ query, representative: props.searchForRepresentative }).then((patients) => {
      const list = patients?.patientList?.map((i) => formatPatientInfoForClaims(i));
      list.length > 0 ? setErrorMessage('') : setErrorMessage(t('No_results_found'));
      setSuggestions(list);
    });
  };

  const selectedItemTemplate = (data) => {
    const dob = formatDate(data?.BirthDay) === 'Invalid date' ? data?.BirthDay : formatDate(data?.BirthDay);
    if (data && data.PatientGuid === undefined) data.PatientGuid = '';
    return (
      <>
        <Tooltip target={`.patientsAutocompleteChipTooltip_${data.PatientGuid}`} position="top">
          <>
            <div>{`${t('PHN')}: ${data?.PHN || t('N_A')}`}</div>
            <div>{`${t('Day_of_birthday.1')}: ${dob}`}</div>
          </>
        </Tooltip>

        <div
          id={`${elementIDs.patientsAutocompleteChip}_${data.PatientGuid.replaceAll('-', '').toUpperCase()}`}
          className={cx('font-bold text-xs md:text-sm', `patientsAutocompleteChipTooltip_${data.PatientGuid}`)}
        >
          {patientFullNameWithAge(data)}
        </div>
      </>
    );
  };

  const itemTemplate = (data) => {
    return (
      <>
        <div className="flex">
          <HighlightSearchQuery query={query} text={patientFullNameWithNicknameAndAge(data)} />
          {/* <div className='ml-1'>
            {`(${data.Age}${data.Sex || ''})`}
          </div> */}
        </div>
        <div>{`${'PHN'}: ${data.PHN}`}</div>
      </>
    );
  };

  const onCreate = (patient) => {
    // close new patient dialog
    setShowNewPatientDialog(false);
    // add new patient to state
    props.onChange({ value: formatPatientInfoForClaims(patient) });
  };

  const onAdd = () => {
    if (props.onAddClick) return props.onAddClick({ query });
    return setShowNewPatientDialog(true);
  };

  const onClear = () => {
    setQuery('');
    setErrorMessage('');
  };

  return (
    <>
      <InputWrapper
        name={props.name}
        label={props.label || t('Patient.1')}
        required={props.required}
        errorMessage={errorMessage}
        hideLabel={props.hideLabel}
        style={props.inputWrapStyles || { minHeight: '70px', height: 'auto', paddingBottom: '20px', width: '100%' }}
      >
        <div className={showAddPatientButton ? 'patient_input_with_add_button' : ''}>
          <AutoComplete
            inputRef={ref}
            className={className}
            inputClassName="w-full"
            id={elementIDs.patientPickerAutocomplete}
            inputId={elementIDs.patientPickerAutocompleteInput}
            name={props.name}
            value={props.value}
            suggestions={suggestions}
            placeholder={props.placeholder || t('Name_PHN_DOB.2')}
            type="text"
            field="label"
            autoHighlight
            scrollHeight={dropdownScrollHeight()}
            delay={750}
            // dropdown
            forceSelection={props.forceSelection}
            disabled={props.disabled}
            multiple={props.multiple || false}
            autoFocus={props.autoFocus || false}
            itemTemplate={itemTemplate}
            completeMethod={(e) => fetchPatients(e.query)}
            selectedItemTemplate={props.multiple ? selectedItemTemplate : null}
            onChange={props.onChange}
            onSelect={() => setQuery('')}
            onClear={onClear}
            onBlur={props.onBlur}
            inputProps={{ 'data-test': 'user-settings-firstName-input' }}
          />

          {showAddPatientButton && (
            <Button
              id={elementIDs.addNewPatient}
              className="p-button-text"
              type="button"
              icon="pi pi-plus"
              tooltip={props.newPatientButtonTooltip || t('Create_new_patient')}
              tooltipOptions={{ position: 'top' }}
              // tabIndex={-1}
              onClick={onAdd}
            />
          )}
        </div>
      </InputWrapper>

      <PatientFormDialog visible={showNewPatientDialog} onHide={() => setShowNewPatientDialog(false)} onCreate={onCreate} />
    </>
  );
});

export default Patient;
