/**
 * This reducer keeps
 */

import actionTypes from '../actions/core.action.types';
import { defaultLayoutMode } from '../../config/stylesConfig';
import { localStorageKeys } from '../../config/localStorageKeysConfig';

export const coreInitialState = {
  theme: JSON.parse(localStorage.getItem(localStorageKeys.themeKey)) ? JSON.parse(localStorage.getItem(localStorageKeys.themeKey)) : 'bluegrey',
  dirty: false,
  window: {
    isMobile: false,
    isMobileDataTableView: false,
    isTablet: false,
    isLandscape: false,
    isMobileOnly: false,
    showNewTeleplanButtons: false,
    width: window.innerWidth,
    height: window.innerHeight,
    clientWidth: document.documentElement.clientWidth,
    clientHeight: document.documentElement.clientHeight,
    isXL: false,
    iPadProInLandscape: false,
    iPadInPortrait: false
  },
  toastMessage: { type: '', message: '', lifeTime: null, title: null, position: '' },
  toastMessagePosition: '',
  print: { type: '', printData: {} },
  showDataScroller: false,
  layoutMode: defaultLayoutMode,
  shouldCloseToastMessage: false,
  showFiltersSidebar: false,
  showSortSidebar: false,
  loading: false,
  showDeletePinDialog: false
};

export default function coreReducer(state = coreInitialState, action) {
  switch (action.type) {
    case actionTypes.WINDOW_RESIZE:
      return {
        ...state,
        window: action.results
      };

    case actionTypes.SET_DIRTY:
      return {
        ...state,
        dirty: true
      };

    case actionTypes.SET_CLEAN:
      return {
        ...state,
        dirty: false
      };

    case actionTypes.SET_COLORS:
      return {
        ...state,
        theme: action.results
      };

    case actionTypes.SET_LOADER:
      return {
        ...state,
        loader: action.results
      };

    case actionTypes.SET_DEEPROW:
      return {
        ...state,
        deepRow: action.results
      };

    case actionTypes.APP_MODE:
      return {
        ...state,
        appmode: action.results.mode,
        readonly: !action.results.editable
      };

    case actionTypes.TOAST_MESSAGE:
      return {
        ...state,
        toastMessage: action.results
      };

    case actionTypes.TOAST_MESSAGE_POSITION:
      return {
        ...state,
        toastMessagePosition: action.results
      };

    case actionTypes.LAYOUT_MODE:
      return {
        ...state,
        layoutMode: action.results
      };

    case actionTypes.CLOSE_TOAST_MESSAGE:
      return {
        ...state,
        shouldCloseToastMessage: action.results
      };

    case actionTypes.TOGGLE_FILTERS:
      return {
        ...state,
        showFiltersSidebar: action.results
      };

    case actionTypes.TOGGLE_SORTING:
      return {
        ...state,
        showSortSidebar: action.results
      };

    case actionTypes.SET_PRINT_DATA:
      return {
        ...state,
        print: action.results
      };

    case actionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.results
      };

      case actionTypes.SET_ELIGIBILITY_ERROR:
        if (state.eligibilityError === action.results) {
          return state; // Avoid re-render if the state hasn't changed
        }
        return {
          ...state,
          eligibilityError: action.results,
        };

    case actionTypes.SHOW_DELETE_PIN_DIALOG:
      return {
        ...state,
        showDeletePinDialog: action.results
      };

    default:
      return state;
  }
}
