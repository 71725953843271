import axios from 'axios';
import { getApiBase, setAuthHeader } from '../../../config';
import { store } from '../../../store';
import { setToastMessage } from '../../core/actions/core.action.creators';
import { t } from '../../../service/localization/i18n';

// interface ChangePasswordPayload {
//   DCNGuid: string;
//   DoctorGuid: string;
//   OldPassword: string;
//   NewPassword: string;
// }

export const changePassword = (payload) => {
  const currentState = store.getState();
  setAuthHeader(currentState);

  return axios
    .put(`${getApiBase()}/users/${payload.DCNGuid}/${payload.DoctorGuid}/renew`, payload)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      const status = error.response.status;
      if (status === 400) {
        // Display error toast message for password reuse not allowed
        store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Password_reuse_not_allowed') }));
      } else if (status === 404) {
        // Display error toast message for wrong old password
        store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Wrong_old_password') }));
      } else if (status === 406) {
        // Display error toast message for password complexity requirements not met
        store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Password_does_not_meet_complexity_requirements') }));
      } else {
        // Log the error and display a generic error message
        console.log('!!!!changePassword', error);
        store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
      }
    });
};

export const createPinCode = (payload) => {
  const currentState = store.getState();
  setAuthHeader(currentState);

  return axios
    .post(`${getApiBase()}/users/${payload.DCNGuid}/device`, payload)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      console.log('!!!!createPinCode', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};

export const editPinCode = (payload) => {
  const currentState = store.getState();
  setAuthHeader(currentState);

  return axios
    .put(`${getApiBase()}/users/${payload.DCNGuid}/device/${payload.DeviceGuid}`, payload)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      console.log('!!!!editPinCode', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};
