import { setToastMessage } from '../../../core/actions/core.action.creators';
import { setErrorMessageAutofill } from '../../actions/patients.action.creators';
import moment from 'moment';
import { validatePHN } from '../../actions/patients.action.creators';
import axios from 'axios';
import { autofillMessages } from './helpers/autofillMessages';
import { uploadImage } from '../../actions/patients.action.creators';
import heic2any from "heic2any";


export const handleAutofill = async (formik, setExtractedText, dispatch, setImage, setShowImage, setIsPHNValid, croppedImage, setLoading, setDisableAbortButoon, setImageTimer, DCNGuid, setValue, resetField, sessionGuid) => {
    let hasError = false;
    let errorMessage = '';
    let scheduler;

    const originalConsoleError = console.error;
    console.error = (...args) => {
        hasError = true;
        errorMessage = args.join(' ');
        originalConsoleError(...args);
    };

    const convertToBase64 = async (file) => {
        try {
            // Create an object URL from the file
            const objectURL = URL.createObjectURL(file);
    
            // Create an image element and load the object URL
            const img = new Image();
            img.src = objectURL;
    
            // Wait for the image to load
            await new Promise((resolve, reject) => {
                img.onload = resolve;
                img.onerror = reject;
            });
    
            // Create a canvas element to draw the image
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
    
            // Convert the canvas to a Base64 string
            const base64String = canvas.toDataURL('image/jpeg').split(',')[1]; // Default to JPEG
            URL.revokeObjectURL(objectURL); // Revoke the object URL to free memory
    
            return base64String;
        } catch (error) {
            console.error('Error converting file to Base64:', error);
            throw new Error('Failed to convert file to Base64.');
        }
    };

    const compressImage = (base64Image, maxWidth, maxHeight) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = `data:image/jpeg;base64,${base64Image}`;
            img.onload = () => {
                let { width, height } = img;
                if (width > maxWidth || height > maxHeight) {
                    const scalingFactor = Math.min(maxWidth / width, maxHeight / height);
                    width = width * scalingFactor;
                    height = height * scalingFactor;
                }
                
                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);
                resolve(canvas.toDataURL('image/jpeg', 0.7).split(',')[1]); // Adjust quality if necessary
            };
        });
    };

    // Listen for the abort signal
    // const abortHandler = async () => {
    //     console.log('Abort signal detected');
    //     if (scheduler) {
    //         await scheduler.terminate(); // Terminate the scheduler to clean up resources
    //         dispatch(setToastMessage({
    //             type: 'info',
    //             message: 'Autofill operation aborted.',
    //         }));
    //         setLoading(false);
    //         setShowImage(true);
    //     }
    // };

    // signal.addEventListener('abort', abortHandler);


    const getFile = () => {
        return new Promise((resolve, reject) => {
          if (croppedImage) {
            resolve(croppedImage);
          } else {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/*, image/heif, image/heic';
            input.style.display = 'none';
            document.body.appendChild(input);
      
            input.onchange = (event) => {
                const file = event.target.files[0];
                if (file) {
                    const validImageTypes = ['image/jpeg', 'image/png', 'image/heic', 'image/jpg', 'image/webp'];
                    if (file.type === "image/heif" || file.type === "image/heic") {
                        // Convert HEIF/HEIC to JPEG
                        try {
                            const converted = heic2any({
                                blob: file,
                                toType: "image/jpeg",
                            });
                            resolve(converted);
                        } catch (error) {
                            reject(new Error("Failed to convert HEIC/HEIF image."));
                        }
                    } else if (validImageTypes.includes(file.type)) {
                        resolve(file);
                    } else {
                        reject(new Error("Unsupported file type."));
                    }
                } else {
                    reject(new Error('No file selected'));
                }
                // Clear the file input value after processing
                input.value = ''; 
                document.body.removeChild(input); 
            };
      
            // Adding a small delay for Safari
            setTimeout(() => {
              input.click();
            }, 200);
          }
        });
      };
    
      const setGenderFromText = (text) => {
        const cleanedText = text.replace(/[-_()]/g, ' ').toLowerCase();

        // Define gender patterns
        const malePattern = /\b(m|male)\b/;
        const femalePattern = /\b(f|female)\b/;

        // Check for patterns in the cleaned text
        if (malePattern.test(cleanedText)) {
            if(setValue) {
                setValue('Sex', 'M', { shouldDirty: true });
            }
            else {
                formik.setFieldValue('Sex', 'M');
            }
            console.log('Gender set to: Male');
        } else if (femalePattern.test(cleanedText)) {
            if(setValue) {
                setValue('Sex', 'F', { shouldDirty: true });
            }
            else {
                formik.setFieldValue('Sex', 'F');
            }
            console.log('Gender set to: Female');
        } else {
            console.log('Gender not recognized. Please enter it manually.');
        }
    };

    try {
        const file = await getFile();
        let base64Image;

        if (!croppedImage) {
            // Convert file to Base64
            base64Image = await convertToBase64(file);
        } else {
            // Convert cropped image Blob to Base64
            base64Image = croppedImage.split(',')[1];
        }

        if (base64Image.length > 4000000) { 
            base64Image = await compressImage(base64Image, 800, 800); // Adjust width and height limits
        }

        setLoading(true);
        dispatch(setErrorMessageAutofill({ type: 'info', message: '', originalMSPErrorMessage: '' }));
        setImageTimer(true);

        const dataURLtoBlob = (dataURL) => {
            const arr = dataURL.split(',');
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
          };

        
        
        const formData = new FormData();
        formData.append('file', croppedImage ? dataURLtoBlob(croppedImage) : file);

        setDisableAbortButoon(true);
        const source = axios.CancelToken.source();
        // signal.addEventListener('abort', () => source.cancel('Request aborted by user'));


        if(resetField) {
            resetField('PHN');
            resetField('BirthDay');
            resetField('FirstName');
            resetField('LastName');
            resetField('Sex');
        }
        else{
            formik.resetForm();
        }

        const response = await axios.post(
            'https://1rr988vv74.execute-api.ca-central-1.amazonaws.com/default/myocr',
            {
                image: base64Image,   // Send Base64 image in request body
                DCNGuid: DCNGuid      // Include DCNGuid in the request body
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache, no-store, must-revalidate'
                },
                timeout: 30000, // Set timeout to 30 seconds
            }
        );

        const { text , s3_url } = response.data;

        setImage(s3_url);

        await uploadImage(DCNGuid, s3_url, sessionGuid);




        // Convert the blob into a URL and update the state to display it
        setDisableAbortButoon(false);

        // Process the extracted text
        setShowImage(false);

        formData.delete('file'); // Clear file from FormData after uploading
        console.log('Extracted text:', text);
        setExtractedText(text);

        setGenderFromText(text); // Set gender based on extracted text


        
            const lines = text.split('\n').filter(Boolean); // Split lines and remove empty lines

            // Ensure there are lines to process
            if (lines.length > 0) {
                // Utility function to clean up OCR text by normalizing spaces
                const cleanOCRText = (text) => {
                    let cleanedText = text.replace(/\s+/g, ' ');
                    cleanedText = cleanedText.replace(/\s*-\s*/g, '-');
                    cleanedText = cleanedText.replace(/(\d)([A-Za-z])/g, '$1 $2');  // Adds space between digit and letter
                    cleanedText = cleanedText.replace(/([A-Za-z])(\d)/g, '$1 $2');  // Adds space between letter and digit
                    cleanedText = cleanedText.replace(/(\d)\s+(st|nd|rd|th)/g, '$1$2'); // Remove space before ordinal suffixes
                    cleanedText = cleanedText.replace(/(Jan|January|Feb|February|Mar|March|Apr|April|May|Jun|June|Jul|July|Aug|August|Sep|September|Oct|October|Nov|November|Dec|December)(\d)/g, '$1 $2');
                    return cleanedText.trim();
                };

                // const validDateRegex = /\b(\d{4}[-/.]\d{1,2}[-/.]\d{1,2}|\d{1,2}[-/.\s]\d{1,2}[-/.\s]\d{4}|w\b\d{1,2}[ ]\d{1,2}[ ]\d{4}|\d{1,2}[-,.]?\s?(?:Jan|January|Feb|FEB|February|Mar|March|Apr|April|May|Jun|June|Jul|July|Aug|August|Sep|September|Oct|October|Nov|November|Dec|December)[-,.]?\s?\d{4}|\d{1,2}[-/.](?:Jan|January|Feb|FEB|February|Mar|March|Apr|April|May|Jun|June|Jul|July|Aug|August|Sep|September|Oct|October|Nov|November|Dec|December)[-/.]\d{4}|(?:Jan|January|Feb|February|FEB|Mar|March|Apr|April|May|Jun|June|Jul|July|Aug|August|Sep|September|Oct|October|Nov|November|Dec|December)[,.]?\s\d{1,2}(?:st|nd|rd|th)?,?\s\d{4})\b/gi;
                // const validDateRegex = /\b(\d{4}[-/.]\d{1,2}[-/.]\d{1,2}|\d{1,2}[-/.\s]\d{1,2}[-/.\s]\d{4}|w\b\d{1,2}[ ]\d{1,2}[ ]\d{4}|\d{1,2}[-,.]?\s?(?:Jan|January|Feb|FEB|February|Mar|March|Apr|April|May|Jun|June|Jul|July|Aug|August|Sep|September|Oct|October|Nov|November|Dec|December)[-,.]?\s?\d{4}|\d{1,2}[-/.](?:Jan|January|Feb|FEB|February|Mar|March|Apr|April|May|Jun|June|Jul|July|Aug|August|Sep|September|Oct|October|Nov|November|Dec|December)[-/.]\d{4}|(?:Jan|January|Feb|February|FEB|Mar|March|Apr|April|May|Jun|June|Jul|July|Aug|August|Sep|September|Oct|October|Nov|November|Dec|December)[,.]?\s\d{1,2}(?:st|nd|rd|th)?,?\s\d{4}|\d{4}[-/.](?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)[a-z]*[-/.]\d{1,2})\b/gi;
                const monthNames = '(Jan|January|Feb|February|Mar|March|Apr|April|May|Jun|June|Jul|July|Aug|August|Sep|September|Oct|October|Nov|November|Dec|December)';

                // const validDateRegex = /\b(\d{4}[-/.]\d{1,2}[-/.]\d{1,2}|\d{1,2}[-/.\s]\d{1,2}[-/.\s]\d{4}|w\b\d{1,2}[ ]\d{1,2}[ ]\d{4}|\d{1,2}[-,.]?\s?(?:Jan|January|Feb|FEB|February|Mar|March|Apr|April|May|Jun|June|Jul|July|Aug|August|Sep|September|Oct|October|Nov|November|Dec|December)[-,.]?\s?\d{4}|\d{1,2}[-/.](?:Jan|January|Feb|FEB|February|Mar|March|Apr|April|May|Jun|June|Jul|July|Aug|August|Sep|September|Oct|October|Nov|November|Dec|December)[-/.]\d{4}|(?:Jan|January|Feb|February|FEB|Mar|March|Apr|April|May|Jun|June|Jul|July|Aug|August|Sep|September|Oct|October|Nov|November|Dec|December)[,.]?\s\d{1,2}(?:st|nd|rd|th)?,?\s\d{4}|\d{4}[-/.](?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)[a-z]*[-/.]\d{1,2}|\d{1,2}[-/.](?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)[a-z]*[-/.]\d{2})\b/gi;
                const validDateRegex = /\b(\d{4}[-/.]\d{1,2}[-/.]\d{1,2}|\d{1,2}[-/.]\d{1,2}[-/.]\d{2}|\d{1,2}[-/.\s]\d{1,2}[-/.\s]\d{4}|w\b\d{1,2}[ ]\d{1,2}[ ]\d{4}|\d{1,2}[-,.]?\s?(?:Jan|January|Feb|FEB|February|Mar|March|Apr|April|May|Jun|June|Jul|July|Aug|August|Sep|September|Oct|October|Nov|November|Dec|December)[-,.]?\s?\d{4}|\d{1,2}[-/.](?:Jan|January|Feb|FEB|February|Mar|March|Apr|April|May|Jun|June|Jul|July|Aug|August|Sep|September|Oct|October|Nov|November|Dec|December)[-/.]\d{4}|(?:Jan|January|Feb|February|FEB|Mar|March|Apr|April|May|Jun|June|Jul|July|Aug|August|Sep|September|Oct|October|Nov|November|Dec|December)[,.]?\s\d{1,2}(?:st|nd|rd|th)?,?\s\d{4}|\d{4}[-/.](?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)[a-z]*[-/.]\d{1,2}|\d{1,2}[-/.](?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)[a-z]*[-/.]\d{2}|\d{4}\s(?:Jan|January|Feb|February|Mar|March|Apr|April|May|Jun|June|Jul|July|Aug|August|Sep|September|Oct|October|Nov|November|Dec|December)\s\d{1,2}(?:st|nd|rd|th)?)\b/gi;

                const normalizeTwoDigitYear = (year) => {
                    const numericYear = parseInt(year, 10); // Parse the two-digit year
                    const currentYear = new Date().getFullYear(); // Get the current year
                    const cutoff = (currentYear % 100) - 90; // Calculate the cutoff for 90 years back
                
                    // Adjust cutoff to handle negative values (e.g., if current year is 2020 and cutoff is -70)
                    const adjustedCutoff = cutoff < 0 ? cutoff + 100 : cutoff;
                
                    if (numericYear <= adjustedCutoff) {
                        return `${Math.floor(currentYear / 100)}${year}`; // Current century
                    } else {
                        return `${Math.floor(currentYear / 100) - 1}${year}`; // Previous century
                    }
                };


                const extractValidDates = (text) => {
                    const cleanedText = cleanOCRText(text);
                    console.log('Cleaned Text:', cleanedText);
                    const matches = cleanedText.match(validDateRegex) || [];
                    console.log('Matches:', matches);
                    return matches.map(match => {
                        let date;
                        match = match.replace(/,/g, ''); // Remove commas
                        if (/[a-zA-Z]/.test(match)) {
                            match = match.replace(/-/g, ' '); // Remove dashes
                            if (/^\d{1,2}[,.]?\s(?:Jan|January|Feb|FEB|February|Mar|March|Apr|April|May|Jun|June|Jul|July|Aug|August|Sep|September|Oct|October|Nov|November|Dec|December)\s\d{4}$/i.test(match)) {
                                date = moment(match, ['DD MMM YYYY', 'D MMM YYYY', 'DD MMMM YYYY', 'D MMMM YYYY', 'DD MMM Do, YYYY', 'D MMM Do, YYYY'], true);
                            } else {
                                console.log('Match:', match);
                                date = moment(match, ['MMM Do YYYY', 'YYYY MMM Do', 'YYYY MMM D', 'YYYY MMM DD' ,'MMMM Do YYYY', 'MMM D YYYY', 'MMMM D YYYY'], true);
                            }
                        } else if (match.includes('-') || match.includes('/') || match.includes('.') || match.includes(' ')) {
                            date = moment(match, [
                                'YYYY/MM/DD', 'MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY-MM-DD', 'DD-MM-YYYY', 'MM-DD-YYYY', 'M-DD-YYYY', 'MM-D-YYYY', 'M-D-YYYY',
                                'M/D/YYYY', 'M/DD/YYYY', 'MM/D/YYYY', 'DD/M/YYYY', 'D/M/YYYY',
                                'D MM YYYY', 'YYYY M D', 'YYYY MM D', 'YYYY M DD', 'YYYY MM DD',
                                'YYYY M DD', 'YYYY-M-D', 'YYYY-M-DD', 'MM D YYYY', 'M DD YYYY',
                                'MM DD YYYY',
                            ], true);
                        } if (/^\d{1,2}[-/]\d{1,2}[-/]\d{2}$/i.test(match)) {
                            // Match formats like xx-xx-xx specifically
                            const parts = match.split(/[-/]/);
                            console.log('Parts:', parts);
                            let [part1, part2, part3] = parts;
                        
                            // Normalize the year part (last part) if it's two digits
                            if (part3.length === 2) {
                                part3 = normalizeTwoDigitYear(part3);
                            }
                        
                            // Reassemble the normalized date
                            const normalizedMatch = `${part1}-${part2}-${part3}`;
                        
                            // Parse using moment
                            date = moment(normalizedMatch, ['MM-DD-YYYY', 'M-DD-YYYY', 'MM-D-YYYY', 'M-D-YYYY','DD-MM-YYYY', 'D-MM-YYYY', 'DD-M-YYYY', 'D-M-YYYY'], true);
                        }
                        
                        if (new RegExp(`^\\d{1,2}[-\\s]${monthNames}[-\\s](\\d{2})$`, 'i').test(match)) {
                            // Handle dates like 21 Nov 99 or 21-Nov-99
                            const parts = match.split(/[-\s]/);
                            const day = parts[0] || '';
                            const month = parts[1] || '';
                            const year = normalizeTwoDigitYear(parts[2] || ''); // Normalize the year
                            console.log('Parts:', day, month, year);
                            date = moment(`${day} ${month} ${year}`, ['D MMM YYYY', 'DD MMM YYYY', 'D MMM YY', 'DD MMM YY'], true);
                        }
                        return date && date.isValid() && date.year() >= 1900 ? date : null;
                    }).filter(Boolean); // Remove null values
                };

                const allDates = extractValidDates(text);
                console.log('Extracted Dates:', allDates.map(date => date.format('YYYY-MM-DD')));

                const findEarliestDate = (dates) => {
                    return dates.reduce((earliest, current) => {
                        return current.isBefore(earliest) ? current : earliest;
                    }, dates[0]);
                };

                if (allDates.length > 0) {
                    const earliestDate = findEarliestDate(allDates);
                    const formattedDOB = moment(earliestDate).format('MM/DD/YYYY');
                    if(setValue) {
                        setValue('BirthDay', formattedDOB, { shouldDirty: true });
                    }
                    else {
                        formik.setFieldValue('BirthDay', formattedDOB);
                    }
                    console.log('Earliest Date (DOB) set to:', formattedDOB);
                } else {
                    console.error('No valid dates found.');
                    dispatch(setErrorMessageAutofill({
                        type: 'warn',
                        message: autofillMessages.DOB_NOT_READABLE,
                    }));
                    setShowImage(true);
                }

                const bcPHNRegex = /9\d{9}/g;
                const onPHNRegex = /\d{10}/g;
                const skPHNRegex = /\d{9}/g;
        
                const extractPHNs = (text, regex) => {
                    const cleanedText = text.replace(/[\s\-—]/g, '');
                
                    const matches = [];
                    let match;
                
                    // Use a loop to capture all overlapping matches
                    while ((match = regex.exec(cleanedText)) !== null) {
                        matches.push(match[0]);
                        // Manually advance the regex index to allow overlapping matches
                        regex.lastIndex = match.index + 1;
                    }
                
                    return matches;
                };
                        
                const validatePHNWithPrefix = async (phn, prefix) => {
                    const prefixedPHN = `${prefix}${phn}`;
                    return new Promise((resolve) => {
                        dispatch(validatePHN(prefixedPHN, (isValid) => resolve(isValid ? prefixedPHN : null)));
                    });
                };
        
                let potentialPHNs = extractPHNs(text, bcPHNRegex);
                for (const phn of potentialPHNs) {
                    const validPHN = await validatePHNWithPrefix(phn, '');
                    if (validPHN) {
                        if(setValue) {
                            setValue('PHNProvince', 'BC', { shouldDirty: true });
                            setValue('Province', 'BC', { shouldDirty: true });
                            setValue('PHN', validPHN, { shouldDirty: true });
                        }
                        else {
                            formik.setFieldValue('PHNProvince', 'BC');
                            formik.setFieldValue('Province', 'BC');
                            formik.setFieldValue('PHN', validPHN);
                        }
                        setIsPHNValid(true);
                        return;
                    }
                }
        
                potentialPHNs = extractPHNs(text, onPHNRegex);
                console.log('Potential PHNs:', potentialPHNs);
                for (const phn of potentialPHNs) {
                    const validPHN = await validatePHNWithPrefix(phn, 'ON');
                    if (validPHN) {
                        if(setValue) {
                            setValue('PHN', validPHN, { shouldDirty: true });
                            setValue('PHNProvince', 'ON', { shouldDirty: true });
                            setValue('Province', 'ON', { shouldDirty: true });
                        }
                        else {
                            formik.setFieldValue('PHN', validPHN);
                            formik.setFieldValue('PHNProvince', 'ON');
                            formik.setFieldValue('Province', 'ON');
                        }
                        setTimeout(() => {
                            const dropdown = document.querySelector('#Province .p-dropdown-label');
                            if (dropdown) dropdown.innerText = 'Ontario'; // Force UI sync
                        }, 0);
                        setIsPHNValid(true);
                        return;
                    }
                }
        
                potentialPHNs = extractPHNs(text, skPHNRegex);
                for (const phn of potentialPHNs) {
                    const validPHN = await validatePHNWithPrefix(phn, 'SK');
                    if (validPHN) {
                        if(setValue) {
                            setValue('PHN', validPHN, { shouldDirty: true });
                            setValue('PHNProvince', 'SK', { shouldDirty: true });
                            setValue('Province', 'SK', { shouldDirty: true });
                        }
                        else {
                            formik.setFieldValue('PHN', validPHN);
                            formik.setFieldValue('PHNProvince', 'SK');
                            formik.setFieldValue('Province', 'SK');
                        }
                        setIsPHNValid(true);
                        return;
                    }
                }
        
                console.error(autofillMessages.PHN_NOT_READABLE);
                setShowImage(true);
        

                if (!hasError) {
                    dispatch(setToastMessage({ type: 'success', message: 'Autofill successful!' }));
                    return true;
                } else {
                    dispatch(setErrorMessageAutofill({
                        type: 'warn',
                        message: errorMessage || 'Error extracting text from the image. Please try again.',
                    }));
                    return false;
                }
            } else {
                dispatch(setErrorMessageAutofill({
                    type: 'warn',
                    message: 'No text found in the image. Please try a different image.',
                }));
                setShowImage(true)
                return false;
            }
        } catch (error) {
            // console.error('Error extracting text:', error);
            dispatch(setToastMessage({
                type: 'error',
                message: errorMessage || 'Error extracting text from the image. Please try again.',
            }));
            return false;
        } finally {
            if (scheduler) {
                await scheduler.terminate(); // Terminate the scheduler to clean up resources
            }
            // setLoading(false);
            console.error = originalConsoleError;
            // signal.removeEventListener('abort', abortHandler); // Cleanup abort handler
        }
    };
