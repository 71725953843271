import React from 'react';
import './AuthFormWrap.scss';

const AuthFormWrap = ({ children, label, hideLogo }) => {
  return (
    <div className="login-body">
      <div className="login-panel grid h-auto">
        <div className="login-panel-header login-panel-header-text">{label}</div>
        <div className="login-panel-content col-12 lg:col-12 pt-4">
          {hideLogo ? null : (
            <div className="w-full text-center pb-4">
              <img src="/assets/layout/images/logo-cm.png" alt="logo" />
            </div>
          )}
          {children}
        </div>
        <div className="px-4 pb-4 text-sm">This system is restricted to authorized users. If you're unauthorized, terminate access now.</div>
      </div>
    </div>
  );
};

export default AuthFormWrap;
