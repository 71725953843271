import { useFormContext } from 'react-hook-form';
import { differenceBy, isEmpty, minBy } from 'lodash';
import { getCodesByType, getInvoicesCatalogMostRecentCodes, getListOfClaims, getPatientClaimData } from '../../../actions/claims.action.creators';
import { useDispatch } from 'react-redux';
import { inputs } from '../helpers/inputs';
import { getDefaultValues, invoiceTypes } from '../helpers/defaultValues';
import { bonusFeeCodes, claimsDefaultFilters, defaultAnatomicCode } from '../../../../config/defaultValuesConfig';
import { useCreateNewRecord } from '../views/TeleplanGroup/hooks/useCreateNewRecord';
import { getPromiseAll } from '../../../../utils/getPromiseAll';
import { steps } from '../helpers/steps';
import { getDxCodesList } from '../helpers/getInitialValuesForEdit';
import { getCategoryType } from '../../../helpers/getCategoryType';
import { useServiceDate } from './useServiceDate';
import { addMissingCodesToRecentList } from '../helpers/updateRecentCodesList';
import { weeksForMW } from '../config/defaultValues';
import { setToastMessage } from '../../../../core/actions/core.action.creators';
import { getRecentCodes } from '../../../../../service/Lookup';
import { setBatchFormToLocalStorage } from '../helpers/formToLocalStorage';
import { t } from '../../../../../service/localization/i18n';
import moment from 'moment';

export const usePatient = () => {
  const { setValue, errors, clearErrors, watch, getValues, isDirty, isNew, firstVisit, localState, setLocalState, isGroup } = useFormContext();
  const dispatch = useDispatch();
  const { onChangeServiceDate } = useServiceDate();
  const { createNewRecord } = useCreateNewRecord();
  const DCNGuid = watch(inputs.dcnguid.name) || '';
  const invoiceType = watch(inputs.payor.name);
  const speciality = watch(inputs.speciality.name);
  const defaultVal = getDefaultValues({ isNew, firstVisit, isGroup });
  const setDirty = !isDirty ? { shouldDirty: true } : {};

  const defaultInputValues = {
    [inputs.claimGuid.name]: DCNGuid,
    [inputs.injuryDate.name]: defaultVal[inputs.injuryDate.name],
    [inputs.icd9.name]: defaultVal[inputs.icd9.name] || [],
    [inputs.icd9.codeDescription]: defaultVal[inputs.icd9.codeDescription] || [],
    [inputs.icd9.codeType]: defaultVal[inputs.icd9.codeType] || [],
    [inputs.noi.name]: defaultVal[inputs.noi.name] || [],
    [inputs.noi.codeDescription]: defaultVal[inputs.noi.codeDescription] || [],
    [inputs.noi.codeType]: defaultVal[inputs.noi.codeType] || [],
    [inputs.aoi.name]: defaultVal[inputs.aoi.name] || [],
    [inputs.aoi.codeDescription]: defaultVal[inputs.aoi.codeDescription] || [],
    [inputs.aoi.codeType]: defaultVal[inputs.aoi.codeType] || [],
    [inputs.anatomicCode.name]: defaultVal[inputs.anatomicCode.name]
  };

  const claimForReset = { ...defaultInputValues, [inputs.claimNumber.name]: '' };

  // Add patient on step 1
  const onPatientChange = async (patients = []) => {
    if (isGroup) addPatientForGroupTable(patients);

    const patientGuid = patients[0]?.PatientGuid;
    const currentPatients = getValues(inputs.patient.name);
    const currentPatientGuid = currentPatients?.[0]?.PatientGuid;

    // VER-853 - Batch - save form state to local storage
    // setBatchFormToLocalStorage({ ...getValues(), [inputs.patient.name]: patients }, localState);

    setValue(inputs.patient.name, patients, setDirty);
    // Clear error message
    clearErrors(inputs.patient.name);

    if (patients?.length > 0) {
      if (patientGuid !== currentPatientGuid) {
        // VER-389 - Teleplan->first screen->Add suggestion for the service date for 36020
        get36010Code({ patientGuid, speciality });

        // Get most recent catalog codes
        setLocalState((prevState) => ({ ...prevState, gettingRecentCodes: true }));
        const recentCodes = await getRecentCodes(patientGuid);
        setLocalState((prevState) => ({ ...prevState, gettingRecentCodes: false }));
        if (recentCodes) {
          // Update resent codes list
          addMissingCodesToRecentList({ formState: watch(), recentCodesList: recentCodes });
        }
      }

      if (patients?.length === 1) {
        // Get patient claims
        if ((invoiceType === invoiceTypes.wsbc || invoiceType === invoiceTypes.icbc) && patients[0]?.PatientGuid) {
          getPatientClaimsData(patients[0]?.PatientGuid);
        }
      }
    }

    if (patients?.length === 0) {
      // Get most recent catalog codes
      setLocalState((prevState) => ({ ...prevState, gettingRecentCodes: true }));
      const recentCodes = await getRecentCodes();
      setLocalState((prevState) => ({ ...prevState, gettingRecentCodes: false }));
      if (recentCodes) {
        // Update resent codes list
        addMissingCodesToRecentList({ formState: watch(), recentCodesList: recentCodes });
      }

      // Reset patient claims
      setValue(inputs.patientClaims.name, {});

      // Reset aditional info for patient
      setLocalState((prevState) => ({ ...prevState, aditionalInfoForPatient: {} }));

      // Do not reset WSBC Claim Data if speciality = 80 (Midwife) and speciality = 18 (anesthetist)
      if (speciality === 80 || speciality === 18) return;

      resetInputValues();
    }
  };

  // Add patient on step 2 for Teleplan Group form
  const addPatientForGroupTable = (patients = []) => {
    const feeCodes = watch(inputs.feeCodes.codeType);
    let updatedGroupRecords = localState.groupRecords;
    const listOfPatients = patients?.map((i) => {
      const recentCodes = localState.listOfPatients.find((x) => x.PatientGuid === i.PatientGuid)?.RecentCodes;
      return { PatientGuid: i[inputs.patientGuid.name], RecentCodes: recentCodes || [] };
    });

    // On remove patient
    if (patients?.length < watch(inputs.patient.name)?.length) {
      updatedGroupRecords = updatedGroupRecords.filter((i) => {
        return patients.some((x) => x.PatientGuid === i[inputs.patient.name][0]?.PatientGuid);
      });
    }

    // On add patient
    if (patients?.length > watch(inputs.patient.name)?.length) {
      const diff = differenceBy(patients, watch(inputs.patient.name), 'PatientGuid');
      const newPatient = diff.length ? diff[0] : null;

      if (!newPatient) return;

      const updatedRecords = [];

      if (feeCodes?.length) {
        for (const code of feeCodes) {
          const newRecord = createNewRecord({ record: watch(), patient: newPatient, feeCode: code });
          const updatedRecord = {
            ...newRecord,
            [inputs.startTime.name]: '',
            [inputs.endTime.name]: '',
            [inputs.duration.name]: 0,
            [inputs.units.name]: 1
          };

          if (patients.length === 1) {
            if (bonusFeeCodes.includes(code.value)) {
              updatedRecords.unshift(updatedRecord);
            } else {
              updatedRecords.push(updatedRecord);
            }
          } else {
            if (!bonusFeeCodes.includes(code.value)) updatedRecords.push(updatedRecord);
          }
        }
      } else {
        // VER-767 - batch->add patients on 2nd step->no patients added if the code is deleted first
        updatedRecords.push({
          ...createNewRecord({ record: watch(), patient: newPatient }),
          [inputs.startTime.name]: '',
          [inputs.endTime.name]: '',
          [inputs.duration.name]: 0,
          [inputs.units.name]: 1
        });
      }

      updatedGroupRecords = [...localState.groupRecords, ...updatedRecords];

      // Get most recent catalog codes
      getMostRecentCodesForGroup(newPatient);
    }

    setLocalState((prevState) => {
      const updatedLocalState = {
        ...prevState,
        listOfPatients,
        groupRecords: updatedGroupRecords
      };
      // VER-853 - Batch - save form state to local storage
      // setBatchFormToLocalStorage(getValues(), updatedLocalState);
      return updatedLocalState;
    });
  };

  const getMostRecentCodesForGroup = (patient) => {
    dispatch(
      getInvoicesCatalogMostRecentCodes({
        loading: localState.step === steps.groupServicesPicker,
        updateRedux: false,
        patientGuid: patient.PatientGuid,
        callback: (mostRecentCodes) => {
          setLocalState((prevState) => {
            const isPatientAvailable = prevState.groupRecords.some(
              (i) =>
                i[inputs.patient.name] &&
                (i[inputs.patient.name][0]?.PatientGuid === patient.PatientGuid || i[inputs.patient.name]?.PatientGuid === patient.PatientGuid)
            );

            const updatedList = prevState.listOfPatients.map((i) => {
              if (i.PatientGuid === patient.PatientGuid) return { ...i, RecentCodes: mostRecentCodes };
              return i;
            });

            if (isPatientAvailable) {
              const updatedGroupRecords = prevState.groupRecords.map((i) => {
                if (
                  i[inputs.patient.name] &&
                  (i[inputs.patient.name][0]?.PatientGuid === patient.PatientGuid || i[inputs.patient.name]?.PatientGuid === patient.PatientGuid)
                ) {
                  return {
                    ...i,
                    [inputs.patient.name]: [{ ...patient, [inputs.recentCodes.name]: mostRecentCodes }]
                  };
                }
                return i;
              });

              return {
                ...prevState,
                groupRecords: updatedGroupRecords,
                listOfPatients: updatedList
              };
            }

            return {
              ...prevState,
              listOfPatients: updatedList
            };
          });
        }
      })
    );
  };

  const getPatientClaimsData = (patientGuid, type = invoiceType) => {
    dispatch(
      getPatientClaimData(patientGuid, (patientClaims) => {
        const feeType = getCategoryType(inputs.feeCodes.codeType, type);
        const feeCodes = watch(inputs.feeCodes.codeType);
        const selectedCodes = { [feeType]: feeCodes };
        addMissingCodesToRecentList({ selectedCodes });

        // Update state if invoice type is WSBC
        if (type === invoiceTypes.wsbc) {
          if (isEmpty(patientClaims) && isDirty) return updateStateForWSBC(watch());

          // Reset initial data if no WSBC records
          if (patientClaims?.WCB?.length === 0) return updateStateForWSBC(claimForReset);

          if (patientClaims?.WCB?.length > 0) {
            const WSBCClaim = minBy(patientClaims.WCB, (i) => {
              const dateCreated = moment(i.DateCreated);
              return moment().diff(dateCreated, 'milliseconds');
            });
            setValue(inputs.patientClaims.name, patientClaims);
            return updateStateForWSBC(WSBCClaim);
          }
        }

        // Update state if invoice type is ICBC
        if (type === invoiceTypes.icbc) {
          if (isEmpty(patientClaims) && isDirty) return updateStateForICBC(watch());

          // Reset initial data if no ICBC records
          if (patientClaims?.ICBC?.length === 0) return updateStateForICBC(claimForReset);

          if (patientClaims?.ICBC?.length > 0) {
            const ICBCClaim = minBy(patientClaims.ICBC, (i) => {
              const dateCreated = moment(i.DateCreated);
              return moment().diff(dateCreated, 'milliseconds');
            });
            setValue(inputs.patientClaims.name, patientClaims);
            updateStateForICBC(ICBCClaim);
            return;
          }
        }
      })
    );
  };

  const updateStateForWSBC = async (claim) => {
    // Initialize default input values for WSBC
    let defaultInputValuesForWSBC = defaultInputValues;

    // Set new input values based on the claim data if available
    if (claim && !isEmpty(claim)) {
      const _claimNumber = claim.NewClaimNumber || (claim[inputs.claimNumber.name] === 'N/A' ? '' : claim[inputs.claimNumber.name]);

      const dxCode = typeof claim.ICD9Code === 'string' ? claim.ICD9Code : '';
      const dxCode2 = typeof claim.DiagnosticCode2 === 'string' ? claim.DiagnosticCode2 : '';
      const dxCode3 = typeof claim.DiagnosticCode3 === 'string' ? claim.DiagnosticCode3 : '';
      const aoiCode = typeof claim.AreaOfInjury === 'string' ? claim.AreaOfInjury : '';
      const noiCode = typeof claim.NatureOfInjury === 'string' ? claim.NatureOfInjury : '';

      // Define asynchronous requests for code data
      const dxCode2Request = dxCode2 ? { dxCode2: getCodesByType({ codeType: inputs.icd9.codeType, query: dxCode2, speciality }) } : {};
      const dxCode3Request = dxCode3 ? { dxCode3: getCodesByType({ codeType: inputs.icd9.codeType, query: dxCode3, speciality }) } : {};

      // Aggregate the requests for code data
      const requests = {
        ...dxCode2Request,
        ...dxCode3Request,
        dxCode: getCodesByType({ codeType: inputs.icd9.codeType, query: dxCode, speciality }),
        aoiCode: getCodesByType({ codeType: inputs.aoi.codeType, query: aoiCode, speciality }),
        noiCode: getCodesByType({ codeType: inputs.noi.codeType, query: noiCode, speciality })
      };

      // Execute all requests concurrently and merge results into a single object
      const results = await getPromiseAll(requests);

      // Get lists of relevant code data
      const dxCodesList = getDxCodesList(claim, results);
      const getAOICode = aoiCode && Array.isArray(results.aoiCode) && results.aoiCode ? results.aoiCode?.find((i) => i.value === aoiCode) : null;
      const getNOICode = noiCode && Array.isArray(results.noiCode) && results.noiCode ? results.noiCode?.find((i) => i.value === noiCode) : null;
      const aoiCodesList = [getAOICode].filter((i) => i !== null);
      const noiCodesList = [getNOICode].filter((i) => i !== null);

      const dxType = getCategoryType(inputs.icd9.codeType, invoiceType);
      const aoiType = getCategoryType(inputs.aoi.codeType, invoiceType);
      const noiType = getCategoryType(inputs.noi.codeType, invoiceType);
      const selectedCodes = {
        [dxType]: dxCodesList,
        [aoiType]: aoiCodesList,
        [noiType]: noiCodesList
      };
      addMissingCodesToRecentList({ selectedCodes });

      // Update defaultInputValuesForWSBC object with new values
      defaultInputValuesForWSBC = {
        ...defaultInputValuesForWSBC,
        [inputs.claimGuid.name]: claim[inputs.claimGuid.name],
        [inputs.claimNumber.name]: _claimNumber,
        [inputs.injuryDate.name]: claim.InjuryDay ? new Date(claim.InjuryDay) : null,
        [inputs.icd9.name]: dxCodesList?.map((i) => i.value),
        [inputs.icd9.codeDescription]: formatCodeDescription(claim.ICD9Text),
        [inputs.icd9.codeType]: dxCodesList,
        [inputs.aoi.name]: formatCode(aoiCode),
        [inputs.aoi.codeDescription]: formatCodeDescription(claim.AOIText),
        [inputs.aoi.codeType]: aoiCodesList,
        [inputs.noi.name]: formatCode(noiCode),
        [inputs.noi.codeDescription]: formatCodeDescription(claim.NOIText),
        [inputs.noi.codeType]: noiCodesList,
        [inputs.anatomicCode.name]: claim?.AnatomicCode?.length > 0 ? claim?.AnatomicCode : defaultAnatomicCode
      };
    }

    // Update the form's input values and clear error messages
    Object.entries(defaultInputValuesForWSBC).forEach(([inputName, inputValue]) => {
      if (inputValue && errors[inputName]) clearErrors(inputName); // Clear error message
      setValue(inputName, inputValue, setDirty); // Update input value
    });
  };

  const updateStateForICBC = async (claim) => {
    // Initialize default input values for ICBC
    let defaultInputValuesForICBC = defaultInputValues;

    // Set new input values based on the claim data if available
    if (claim && !isEmpty(claim)) {
      const _claimNumber = claim.NewClaimNumber || (claim[inputs.claimNumber.name] === 'N/A' ? '' : claim[inputs.claimNumber.name]);

      const dxCode = typeof claim.ICD9Code === 'string' ? claim.ICD9Code : '';
      const dxCode2 = typeof claim.DiagnosticCode2 === 'string' ? claim.DiagnosticCode2 : '';
      const dxCode3 = typeof claim.DiagnosticCode3 === 'string' ? claim.DiagnosticCode3 : '';

      // Define asynchronous requests for code data
      const dxCode2Request = dxCode2 ? { dxCode2: getCodesByType({ codeType: inputs.icd9.codeType, query: dxCode2, speciality }) } : {};
      const dxCode3Request = dxCode3 ? { dxCode3: getCodesByType({ codeType: inputs.icd9.codeType, query: dxCode3, speciality }) } : {};

      // Aggregate the requests for code data
      const requests = {
        ...dxCode2Request,
        ...dxCode3Request,
        dxCode: getCodesByType({ codeType: inputs.icd9.codeType, query: dxCode, speciality })
      };

      // Execute all requests concurrently and merge results into a single object
      const results = await getPromiseAll(requests);

      // Get lists of relevant code data
      const dxCodesList = getDxCodesList(claim, results);
      // Add missing dx codes to the quick pick list
      const dxType = getCategoryType(inputs.icd9.codeType, invoiceType);
      const selectedCodes = { [dxType]: dxCodesList };
      addMissingCodesToRecentList({ selectedCodes });

      // Update defaultInputValuesForICBC object with new values
      defaultInputValuesForICBC = {
        ...defaultInputValuesForICBC,
        [inputs.claimGuid.name]: claim[inputs.claimGuid.name],
        [inputs.claimNumber.name]: _claimNumber,
        [inputs.icd9.name]: dxCodesList?.map((i) => i.value),
        [inputs.icd9.codeDescription]: formatCodeDescription(claim.ICD9Text),
        [inputs.icd9.codeType]: dxCodesList
      };
    }

    // Update the form's input values and clear error messages
    Object.entries(defaultInputValuesForICBC).forEach(([inputName, inputValue]) => {
      if (inputValue && errors[inputName]) clearErrors(inputName); // Clear error message
      setValue(inputName, inputValue, setDirty); // Update input value
    });
  };

  const resetInputValues = () => {
    Object.entries(claimForReset).forEach(([inputName, inputValue]) => {
      if (inputValue && errors[inputName]) clearErrors(inputName); // Clear error message
      setValue(inputName, inputValue); // Update input value
    });
  };

  const formatCode = (code) => {
    return Array.isArray(code) ? code : code?.split(',');
  };

  const formatCodeDescription = (description) => {
    if (Array.isArray(description)) return description;
    return description?.split('|');
  };

  const get36010Code = async ({ patientGuid, speciality }) => {
    // Check if additional information for patient with the given guid already exists
    const isDataFor36010 = localState.aditionalInfoForPatient.patientGuid === patientGuid;

    // Check if the speciality is 80 (midwife) and additional information for the patient is not yet retrieved
    if (speciality === 80 && !isDataFor36010) {
      // Prepare request parameters for retrieving claims with fee code "36010" for the patient
      const requestParams = { patientGuid, query: { ...claimsDefaultFilters, FeeCode: '36010' } };

      // Retrieve list of claims
      const results = await getListOfClaims(requestParams);

      if (results) {
        // Check if any claims with fee code "36010" are found
        if (results.claimList.length > 0) {
          // Get the latest claim with fee code "36010"
          const latestCode = results.claimList[0];
          // Convert service date to a Date object using Moment.js
          const serviceDate = moment(latestCode.ServiceDate).toDate();

          // Check if fee code "36020" is selected
          const feeCodes = getValues(inputs.feeCodes.codeType);
          const is36020Selected = feeCodes.some((i) => i.value === '36020');

          if (is36020Selected) {
            dispatch(setToastMessage({ type: 'info', message: t('The_date_updated_based_on_36010') }));
            // Add 14 weeks to the service date for fee code "36010"
            const updatedServiceDateForMW = moment(serviceDate).add(weeksForMW, 'weeks');
            onChangeServiceDate(updatedServiceDateForMW);
          }

          // Update additional information for the patient in local state
          setLocalState((prevState) => ({ ...prevState, aditionalInfoForPatient: { feeCode: '36010', serviceDate, patientGuid } }));
        } else {
          // Update additional information for the patient with empty values if no claims with fee code "36010" are found
          setLocalState((prevState) => ({ ...prevState, aditionalInfoForPatient: { feeCode: '', serviceDate: null, patientGuid } }));
        }
      }
    } else {
      // Clear additional information for the patient if the speciality is not 80 or additional information already exists
      !isEmpty(localState.aditionalInfoForPatient) && setLocalState((prevState) => ({ ...prevState, aditionalInfoForPatient: {} }));
    }
  };

  return {
    get36010Code,
    onPatientChange,
    resetInputValues,
    updateStateForWSBC,
    updateStateForICBC,
    addPatientForGroupTable,
    getPatientClaimsData,
    claimForReset
  };
};
