import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { InputMask } from 'primereact/inputmask';
import { InputWrapper } from '../../../Inputs';

import { phoneValidation } from '../../../../modules/config/validations';
import { t } from '../../../../service/localization/i18n';

const Phone = () => {
  const name = 'Phone';
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <InputWrapper name={name} label={t('Phone')} errorMessage={errors[name]?.message}>
      <Controller
        name={name}
        control={control}
        rules={{
          validate: phoneValidation
        }}
        render={({ field }) => (
          <InputMask
            name={name}
            inputMode="numeric"
            mask="(999) 999-9999"
            placeholder="(999) 999-9999"
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
    </InputWrapper>
  );
};

export default Phone;
