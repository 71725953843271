import React from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { Tooltip } from 'primereact/tooltip';
import { Message } from 'primereact/message';
import { Divider } from 'primereact/divider';
import { ScrollPanel } from 'primereact/scrollpanel';
import FacilityLayout from '../../../../../../../components/Layouts/Facility/FacilityLayout';
import TimeWithDuration from '../../../../../../../components/Layouts/Time/TimeWithDuration';

import { inputs } from '../../../helpers/inputs';
import { location_codes, rural_codes } from '../../../../../../common/contants';
import { submissionCodes } from '../../../../../helpers/mapper';
import { invoiceTypes } from '../../../helpers/defaultValues';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { currencyFormat } from '../../../../../../utils/currencyFormat';
import { commonInputs } from '../../../../../../config/commonInputsConfig';
import { useGroupPickerInputs } from '../hooks/useGroupPickerInputs';
import { specPHN } from '../../../../../../config/defaultValuesConfig';
import { formatDate } from '../../../../../../utils/formatDate';
import { formatPHN } from '../../../../../../utils/formatPHN';
import { isSupplementary } from '../../../../../../config/specialitiesConfig';
import { isArray } from 'lodash';
import { t } from '../../../../../../../service/localization/i18n';
import cx from 'classnames';

const Location = ({ locationCode }) => {
  const currentLocationCode = location_codes.find((i) => i.value === locationCode);

  return (
    <div id={elementIDs.previewLocation} className="flex align-items-center mb-1">
      {`${t('Location')}: ${currentLocationCode?.value}`}

      <Tooltip id={elementIDs.previewLocationTooltip} className="locationTooltip" target=".locationInfoIcon" position="top" />
      <i
        id={elementIDs.previewLocationIcon}
        className="locationInfoIcon pi pi-info-circle ml-3 pointer"
        data-pr-tooltip={currentLocationCode?.label}
      />
    </div>
  );
};

const PatientInfo = () => {
  const { watch, localState } = useFormContext();
  const rowGap = '0.3rem';
  const isSupplemental = isSupplementary(watch(inputs.speciality.name), watch(inputs.payor.name));

  const phn = (patient) => {
    if (watch(commonInputs.submitWithGenericPHN.name) === '1') return specPHN;
    if (patient?.PHNProvince && patient?.PHN && patient?.PHN !== 'N/A') {
      return `${patient?.PHNProvince} ${formatPHN(patient?.PHN, patient?.PHNProvince)}`;
    }
    return formatPHN(patient?.PHN, patient?.PHNProvince);
  };

  const serviceTime = (i) => {
    if (isSupplemental) return null;

    const startTime = i[inputs.startTime.name];
    const endtTime = i[inputs.endTime.name];

    const noTime = (
      <div className="flex">
        <div className="pr-3">{`${t('Time')}:`}</div>
        <div>{t('N_A')}</div>
      </div>
    );

    const time = (
      <span>
        {`${startTime} - ${endtTime}`}
        {/* <TimeWithDuration startTime={startTime} endTime={endtTime} /> */}
      </span>
    );

    if (!startTime && !endtTime) return noTime;

    return time;
  };

  const codes = (i, index) => {
    const codesOnly = i[inputs.icd9.codeType]?.map((code) => code.value);
    return (
      <>
        <Tooltip
          id={elementIDs.previewReferralTooltip}
          key={`tt_${index}`}
          className="locationTooltip"
          target={`.dxCodesDescriptionTooltip_${index}`}
          position="top"
        >
          {i[inputs.icd9.codeType].map((code, dxIndex) => {
            return (
              <div className="flex col-12 p-0 white-space-normal" key={`${code.value}_${dxIndex}`}>
                <div className="codeDescriptionValue">{`${code.label}`}</div>
              </div>
            );
          })}
        </Tooltip>

        <div className={cx('flex', `dxCodesDescriptionTooltip_${index}`)}>
          <div className="pr-2">{`${t('Dx')}:`}</div>
          <div className="pr-3 w-full">{`${codesOnly?.join()?.replaceAll(',', ', ')}`}</div>
        </div>
      </>
    );
  };

  const referral = (i) => {
    const refToBy = i[inputs.refToBy.name];
    const referral = i[inputs.referral.name];
    const referralDescription = i[inputs.referral.codeDescription];
    const refToByLabel = refToBy === 'B' ? 'by' : refToBy === 'T' ? 'to' : '';

    if (refToBy && referralDescription?.length && referral?.length) {
      return (
        <div id={elementIDs.previewReferral}>
          <Tooltip id={elementIDs.previewReferralTooltip} className="locationTooltip" target=".referredInfoIcon" position="top" />

          <div>
            {`${t('Referred.2')}. ${refToByLabel} `}
            <span className="referredInfoIcon" data-pr-tooltip={referralDescription[0]}>
              {`#${referral[0]}`}
            </span>
          </div>
        </div>
      );
    }

    return null;
  };

  const submissionCodeLayout = (record) => {
    if (record[inputs.isDuplicated.name]) {
      const submissionCode = submissionCodes.find((i) => i.value === record[inputs.submission.name]);
      return submissionCode?.label;
    }

    return null;
  };

  const billing = (i, index) => {
    const feeCode = i[inputs.feeCodes.codeType][0];
    const units = Number(i[inputs.units.name]);

    return (
      <div className="flex">
        <Tooltip id={elementIDs.previewReferralTooltip} className="locationTooltip" target={`.billingInfoTooltip_${index}`} position="top">
          <div className="flex col-12 p-0 white-space-normal">
            <div id={elementIDs.previewFeeCodeTextTooltip}>{`${feeCode?.label}, ${currencyFormat(feeCode.amount)}`}</div>
          </div>
        </Tooltip>

        <div className={`billingInfoTooltip_${index}`}>
          <span className="font-bold pr-1">{feeCode?.value}</span>
          {`x ${units} = ${currencyFormat(feeCode?.amount * units)}`}
        </div>
      </div>
    );
  };

  return (
    <ScrollPanel className="teleplan_group_review_patients_info_section">
      <div className="flex flex-column " style={{ rowGap: '1.1rem' }}>
        {localState.groupRecords?.map((i, index) => {
          const patient = i[inputs.patient.name][0];
          if (!patient) return null;
          if (patient.PatientGuid === undefined) return null;
          return (
            <div className="flex flex-column" key={`lp_${patient?.PatientGuid}_${index}`} style={{ rowGap: rowGap }}>
              <div>
                <div className="flex" style={{ columnGap: '0.5rem' }}>
                  <div style={{ minWidth: '20px', maxWidth: '20px' }}>{`${index + 1}.`}</div>

                  <div className="flex" style={{ columnGap: '1rem' }}>
                    <div id={`previewPatientName_${patient?.PatientGuid.replaceAll('-', '').toUpperCase()}`} className="font-bold">
                      {`${patient?.LastName}, ${patient?.FirstName} ${patient?.MidName || ''}`}
                    </div>
                    <div id={`previewPatientPHN_${patient.PatientGuid.replaceAll('-', '').toUpperCase()}`}>
                      {`${phn(patient)}, ${formatDate(patient?.BirthDay)}`}
                      {patient?.LfpPanel && <>{`, ${t('LFP')}`}</>}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-column" style={{ paddingLeft: '26px', rowGap: rowGap }}>
                <div className="flex" style={{ columnGap: '1.5rem' }}>
                  {serviceTime(i)}
                </div>

                <div className="flex" style={{ columnGap: '1.5rem' }}>
                  {codes(i, index)}
                </div>

                <div>{referral(i)}</div>

                <div>{billing(i, index)}</div>

                <div>{submissionCodeLayout(i)}</div>

                <div>{i[inputs.comment.name]}</div>
              </div>
            </div>
          );
        })}
      </div>
    </ScrollPanel>
  );
};

const PreviewBodyLayout = () => {
  const practitioners = useSelector((state) => state.clinic.members);
  const { watch, isMobile } = useFormContext();
  const { calculateTotalForGroupPicker } = useGroupPickerInputs();

  const serviceDate = watch(inputs.serviceDate.name);
  const currentServiceDate = isArray(serviceDate) && serviceDate.length ? serviceDate[0] : serviceDate;
  const facility = watch(inputs.facility.name);
  const subFacility = watch(inputs.subFacility.name);
  const ruralCode = rural_codes.find((i) => i.value === watch(inputs.ruralCode.name)?.value);
  const submissionCode = watch(inputs.submission.name) ? submissionCodes.find((i) => i.value === watch(inputs.submission.name)) : submissionCodes[0];

  const elementsRowGap = '5px';

  const treatedBy = () => {
    const currentPreactitioner = practitioners.find((i) => i.DoctorGuid?.toUpperCase() === watch(inputs.practitioner.name)?.toUpperCase());
    return `${currentPreactitioner?.LastName}, ${currentPreactitioner?.FirstName}` || '';
  };

  const serviseDateChipTemplate = <span id={elementIDs.serviceDate}>{formatDate(currentServiceDate)}</span>;

  return (
    <div id={elementIDs.previewPanel} className="flex justify-content-center w-full">
      <div className="mb-4 mt-2 w-full">
        <div className={cx('mt-1', isMobile ? '' : 'p-3')}>
          <div className={cx('flex col-12 p-0')} style={{ height: '108px' }}>
            {/* Doctor */}
            <div className={cx('p-0 flex flex-column', isMobile ? 'col-6' : 'col-7')} style={{ rowGap: elementsRowGap }}>
              <div id={elementIDs.previewPractitioner} className="font-bold">
                {`${treatedBy().toUpperCase()}`}
              </div>

              <div id={elementIDs.previewPayeeNum}>
                {`${t('Practitioner')}/${t('Payee')}: #${watch(inputs.practitionerNumber.name)}/#${watch(inputs.payeeNumber.name)}`}
              </div>

              <FacilityLayout data={{ facility, subFacility }} />

              {ruralCode && !isSupplementary(watch(inputs.speciality.name), watch(inputs.payor.name)) && (
                <div id={elementIDs.previewRuralCode}>{ruralCode.label === 'N/A' ? t('No_Rural_Code') : ruralCode.label}</div>
              )}

              <Location locationCode={watch(inputs.locationCode.name)} />
            </div>

            <div className={cx('pl-3 pr-0 py-0 flex flex-column', isMobile ? 'col-6' : 'col-5')} style={{ rowGap: elementsRowGap }}>
              <div id={elementIDs.previewInvoiceType} className="font-bold">
                {watch(inputs.payor.name)}
              </div>

              {watch(inputs.payor.name) === invoiceTypes.wsbc && (
                <div id={elementIDs.previewWSBCClaimNumber}>
                  {`${t('Claim')}# ${watch(inputs.claimNumber.name) || t('N_A')} / ${formatDate(watch(inputs.injuryDate.name))}`}
                </div>
              )}

              <div>
                <Message severity="info" content={serviseDateChipTemplate} />
              </div>

              <div id={elementIDs.previewSubmitionCode}>{submissionCode?.label}</div>
            </div>
            <div></div>
          </div>

          <Divider />

          <PatientInfo />

          <Divider />

          <div className="col-8 p-0 mt-3">
            <div className="flex col-12 p-0">
              <div className="mr-3 font-bold">{`${t('Total_amount')}: `}</div>
              <div id={elementIDs.previewTotalAmount} className="font-bold">
                <span>{currencyFormat(calculateTotalForGroupPicker())}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewBodyLayout;
