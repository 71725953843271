import { localStorageKeys } from '../config/localStorageKeysConfig';
import { isMobile, isTablet } from './deviceDetect';
import CryptoJS from 'crypto-js';

export const hashPinCode = async (pin) => {
  if (window.crypto && window.crypto.subtle) {
    // Modern browser support
    const encoder = new TextEncoder();
    const data = encoder.encode(pin);

    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

    return hashHex;
  } else {
    // Fallback for browsers without Web Crypto support
    return CryptoJS.SHA256(pin).toString(CryptoJS.enc.Hex);
  }
};

export const showPin = () => {
  return isMobile() || isTablet();
};

export const shouldUsePin = () => {
  const storedPinData = JSON.parse(localStorage.getItem(localStorageKeys.pinCode));
  return storedPinData?.usePin && showPin();
};
